import * as React from "react";

function MaxerlRed(props) {
  return (
    <svg
      id="prefix__Ebene_1"
      data-name="Ebene 1"
      viewBox="0 0 104 48"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path">
          <path fill="none" d="M8.59 9.36H95.4v29.31H8.59z" />
        </clipPath>
        <style>{".prefix__cls-3{fill:#d80d41}"}</style>
      </defs>
      <g clipPath="url(#prefix__clip-path)">
        <path
          style={{ fill: "#d80d41", stroke: "none" }}
          className="prefix__cls-3"
          d="M44.61 32.32c.39.19.57-.62.76-.93s1.76-2.68 2.61-4 1.64-2.67 2.4-4a18.15 18.15 0 00.83-2c0-.11.1-.22.16-.33s.12 0 .12.06a5.09 5.09 0 00.27 1.9q.59 2.22 1.2 4.44c.26 1 .53 2 .84 2.92.14.46.46 1.1.6 1.46a9.47 9.47 0 01.49 1.34c.07.27.16.69.23.92s.47 1.4.68 2.23a6.49 6.49 0 00.29.95 3.45 3.45 0 00.44.73c.13.17.18.27.26.39a2 2 0 00.31.39l.1-.05c-.05-.28-.1-.57-.16-.85-.41-2-.81-4-1.23-5.91s-.68-3.71-1.2-5.52c-.44-1.55-.79-3.12-1.19-4.67-.3-1.12-.63-2.24-1-3.35a3.53 3.53 0 00-.16-.58 2.65 2.65 0 00-.44-.75.53.53 0 00-.91.17 1.38 1.38 0 01-.82.82 2.52 2.52 0 00-1.35 1.61c-.32 1.08-.59 2.17-.94 3.24s-.77 2.27-1.21 3.39c-.28.71-.65 1.4-1 2.1a2.63 2.63 0 00-.1.43c0 .09-.17.53-.35 1s-.14.63-.39 1.13c-.36.94-.24 1.32-.14 1.36M49.78 10.88a1.63 1.63 0 001.1 1.49 1.81 1.81 0 001.61-.21 1.62 1.62 0 000-2.12 2.08 2.08 0 00-1.42-.68 1.42 1.42 0 00-1.29 1.52"
        />
        <path
          style={{ fill: "#d80d41", stroke: "none" }}
          className="prefix__cls-3"
          d="M29.17 15.1a13.71 13.71 0 01-2 .08c0-.11.4 0 .55-.08a16.12 16.12 0 00-3.28.21h.79a9.67 9.67 0 01-1.34.12l-.05-.12a17.05 17.05 0 01-2.29.15l-.15.06c.41.09.79-.15 1.25 0-.67.12-1.68.11-2.43.23a10.77 10.77 0 01-1.25.06.77.77 0 01.49-.09 3.2 3.2 0 00-.78 0h.21a6.79 6.79 0 01-1.45.15c0-.05.08-.06.14-.09-.26-.11-.14.17-.69.17-.35 0-.61.09-.9 0a6.62 6.62 0 011-.12 10.67 10.67 0 00-2.78.27h-.41l.2.06c-1.3.21-2.72.28-4 .5-.09.24-1.13.13-1 .38l-.34.09c0 .1.37.1.2.21 1.45.07 2.92-.12 4.4-.18a7.23 7.23 0 001.3 0 .44.44 0 01.5-.06c9-.75 17.92-1.41 27.38-1.58 4.2-.17 8.54-.2 12.71-.06 5.07.07 10.22.25 15.09.65a28 28 0 012.76.29c1.54.1 3 .28 4.58.29 1.24.24 2.69.25 4 .44 4.25.37 8.28 1 12.36 1.54.58 0 1.21 0 1.47-.3 0-.22 0-.46-.49-.54-4.34-.55-8.6-1.19-13.14-1.55l-.12-.07c-1.68-.07-3.1-.42-4.86-.38a20.8 20.8 0 00-3.8-.35c-.49-.15-1.24-.05-1.73-.2-1.16-.06-2.29-.18-3.42-.18l-.11-.1c-6.84-.44-14.31-.59-21.28-.55-1.28 0-2.49.07-3.83.06-4.34.22-9 .23-13.46.55"
        />
      </g>
    </svg>
  );
}

export default MaxerlRed;
