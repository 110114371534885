import React, { useEffect } from "react";
import "./index.css";
import MaxerlRed from "../MaxerlRed";
import { MDBBtn } from "mdbreact";
import { FaLinkedinIn, FaXing } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import MaxerlWhite from "../MaxerlWhite";
import { NavHashLink } from "react-router-hash-link";

export default function KarinWeissScreen(props) {

  useEffect(() => {
    const currentPage = props.location.pathname;
    props.trackPage(currentPage);
  }, [props]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
//      console.log(window.scrollY);
      let minus = 1040;
      if (document.body.clientHeight > 1900) {
        minus = 1300;
      }
      if (window.scrollY > document.body.clientHeight - minus) {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "180px";
          redLogo.style.marginRight = "-65px";
        }
      } else {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];
          const redLogoFixed = document.getElementsByClassName(
            "redLogoUnternehmenFixed"
          )[0];
          redLogoFixed.style.display = "none";

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "90px";
        }
      }
    });
  }, []);

  return (
    <div id="all">
      <div className="allContainer">
        <div className="mainContainerUnt">
          <div className="wideImgBox">
            <h1>
              Im Mittelpunkt
              <br /> stehen Sie für mich –<br /> mit Ihren Interessen,
              <br /> Ihren Bedürfnissen
              <br /> und Ihren Zielen.
            </h1>
          </div>
          <p className="underImgText">
            Als F&uuml;hrungskraft, ehemalige Unternehmerin und
            Leadership-Trainerin/Coach verstehe ich die Interessen und
            Anforderungen der Unternehmen. Aufgrund meiner vielf&auml;ltigen
            Erfahrung als Outplacerin kenne ich auch die Bedürfnisse und
            Herausforderungen der Kandidat*innen. <br />
            <br />
            <strong style={{ fontWeight: 600 }}>
              Meinen Schwerpunkt als Recruiterin im Bereich IT setze ich dank
              meiner guten Vernetzung, bestem fachlichen Support und meiner
              Faszination für die Vielfalt und die rasche Entwicklung in diesem
              Bereich.
            </strong>
            <br />
            <br />
            <strong style={{ fontWeight: 600 }}>Ich biete Ihnen:</strong>
            <ul className="karinUl" style={{ marginBottom: 0 }}>
              <li>
                Professionelle Unterstützung durch vielf&auml;ltigen Background
                und beste Vernetzung
              </li>
              <li>Erfahrung im Personalmanagement</li>
              <li>Leidenschaft für Ihre Ideen und Ziele</li>
              <li>ein Gesp&uuml;r für Teams, Menschen und Talente</li>
            </ul>
            <br />
            <strong style={{ fontWeight: 600 }}>
              Ihr Vertrauen ist mein Ziel. Denn eine langfristige, gute
              Partnerschaft ist mir wichtig.
            </strong>
            <p className="rightUnder">
              <strong style={{ fontWeight: 600 }}>
                Ich freue mich darauf, Sie kennen zu lernen.
              </strong>{" "}
              Rufen sich mich an oder schreiben Sie mir eine Email unter:
              <br />
              <br />
              +43 650 49 789 64
              <br />{" "}
              <a className="mailHover" href="mailto:office@karin-weiss.at">
                office@karin-weiss.at
              </a>
            </p>
          </p>
          <div className="footerUnternehmen" id="footerDesktop">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>

            <div className="right">
              <MaxerlRed width="150px" className="redLogoUnternehmen" />
              <MaxerlRed
                width="150px"
                className="redLogoUnternehmenFixed"
                style={{ marginRight: -65 }}
              />
              <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
              <p>
                Mag.a Karin Weiss
                <br /> +43 650 49 789 64
                <br />{" "}
                <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
                <br />
                <br />
                <NavHashLink to="/impressum#root">Impressum</NavHashLink>
                <NavHashLink to="/agbs#root">AGBs</NavHashLink>
                <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
              </p>
            </div>
          </div>
          <div className="footerUnternehmen" id="footerMobile">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="right" id="mobileRight">
        <MaxerlRed width="150px" className="redLogoUnternehmen" />
        <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
        <p>
          Mag.a Karin Weiss
          <br /> +43 650 49 789 64
          <br />{" "}
          <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
          <br />
          <br />
          <NavHashLink to="/impressum#root">Impressum</NavHashLink>
          <NavHashLink to="/agbs#root">AGBs</NavHashLink>
          <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
        </p>
      </div>
      <div className="whiteUnder"></div>
    </div>
  );
}
