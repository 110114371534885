import React from "react";
import "./index.css";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

export default function NavigatorMobile() {
  const [menu, setMenu] = React.useState(false);

  const handleClick = () => {
    const menuBtn = document.getElementById("menu-btn");
    const menuLogo = document.getElementById("mobileLogo");
    const body = document.body;
    const over = document.getElementById("overContainer");
    console.log(menuBtn);

    if (!menu) {
      menuBtn.classList.add("open");
      menuBtn.classList.add("show");
      menuLogo.classList.add("showMobile");
      setTimeout(() => {
        body.style.overflowY = "hidden";
        over.style.overflowY = "hidden";
      }, 395);

      setMenu(true);
    } else {
      menuBtn.classList.remove("open");
      menuBtn.classList.remove("show");
      menuLogo.classList.remove("showMobile");
      setTimeout(() => {
        body.style.overflowY = "scroll";
        over.style.overflowY = "scroll";
      }, 310);

      setMenu(false);
    }
  };

  const scrollRoot = () => {
    handleClick();
    const id = "root";
    const yOffset = 0;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <div className="navMobile" id="top">
      <div
        className="overlayContainer"
        id="overContainer"
        style={menu === true ? { width: "100%" } : { width: "0%" }}
      >
        <ul>
          <li className="font-weight-bold">
            <NavLink to="/unternehmen" onClick={scrollRoot}>
              Unternehmen
            </NavLink>
          </li>
          <li style={{ fontWeight: 200 }}>
            <NavHashLink
              to="/unternehmen#recruiting"
              onClick={handleClick}
              scroll={(el) => el.scrollIntoView(true)}
            >
              Recruiting
            </NavHashLink>
          </li>
          <li style={{ fontWeight: 200 }}>
            <NavHashLink
              to="/unternehmen#coachingMobile"
              onClick={handleClick}
              scroll={(el) => el.scrollIntoView(true)}
            >
              Coaching
            </NavHashLink>
          </li>
        </ul>
        <ul>
          <li className="font-weight-bold">
            <NavLink to="/kandidatInnen" onClick={scrollRoot}>
              Kandidat*innen
            </NavLink>
          </li>
          <li style={{ fontWeight: 200 }}>
            <NavHashLink
              to="/kandidatInnen#bewerbung"
              onClick={handleClick}
              scroll={(el) => el.scrollIntoView(true)}
            >
              Bewerbung
            </NavHashLink>
          </li>
          <li style={{ fontWeight: 200 }}>
            <NavHashLink
              to="/kandidatInnen#coaching"
              onClick={handleClick}
              scroll={(el) => el.scrollIntoView(true)}
            >
              Coaching
            </NavHashLink>
          </li>
        </ul>
        <ul>
          <li className="font-weight-bold">
            <NavHashLink to="/karinWeiss" onClick={scrollRoot}>
              Karin Weiss
            </NavHashLink>
          </li>
        </ul>
        <ul>
          <li className="font-weight-bold">
            <NavHashLink to="/kontakt" onClick={scrollRoot}>
              Kontakt
            </NavHashLink>
          </li>
        </ul>
        <ul>
          <li style={{ fontWeight: 200 }}>
            <NavHashLink to="/agbs" onClick={scrollRoot}>
              AGBs
            </NavHashLink>
          </li>
          <li style={{ fontWeight: 200 }}>
            <NavHashLink to="/impressum" onClick={scrollRoot}>
              Impressum
            </NavHashLink>
          </li>
          <li style={{ fontWeight: 200 }}>
            <NavHashLink to="/datenschutz" onClick={scrollRoot}>
              Datenschutz
            </NavHashLink>
          </li>
        </ul>
      </div>

      <NavHashLink
        to="/#root"
        scroll={(el) => el.scrollIntoView(true)}
        onClick={menu === true ? scrollRoot : () => console.log("open")}
      >
        {/* <Logo
          width="200px"
          className={menu === true ? "mobileLogo light" : "mobileLogo"}
          id="mobileLogo"
        /> */}
        {menu === true ? (
          <img
            src={require("../../assets/KW_Logo_White.png")}
            id="mobileLogo"
            className="mobileLogo"
            alt="logo"
          />
        ) : (
          <img
            src={require("../../assets/KW_Logo.png")}
            id="mobileLogo"
            className="mobileLogo"
            alt="logo"
          />
        )}
      </NavHashLink>

      <div
        className="menu-btn"
        id="menu-btn"
        onClick={handleClick}
        style={{ marginLeft: "50px" }}
      >
        <div className="menu-btn__burger"></div>
      </div>
    </div>
  );
}
