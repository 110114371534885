import * as React from "react";

function KandidatMobile(props) {
  return (
    <svg
      id="prefix__Ebene_1"
      data-name="Ebene 1"
      viewBox="0 0 455 135"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path">
          <path fill="none" d="M7.9 7.3h438.2v117.4H7.9z" />
        </clipPath>
        <style>
          {
            ".prefix__cls-3,.prefix__cls-4{fill:none}.prefix__cls-2{clip-path:url(#prefix__clip-path)}.prefix__cls-3,.prefix__cls-4{stroke:#fff;stroke-width:2px}.prefix__cls-4{stroke-linecap:round}"
          }
        </style>
      </defs>
      <g className="prefix__cls-8">
        <path
          className="prefix__cls-3"
          style={{ fill: "none" }}
          d="M10.46 41.2a1.53 1.53 0 00-1.56 1.56v78.78a1.52 1.52 0 001.56 1.55h63.72a1.52 1.52 0 001.56-1.55V42.76a1.53 1.53 0 00-1.56-1.56z"
        />
        <path
          style={{ stroke: "white", fill: "none" }}
          className="prefix__cls-3"
          d="M50.7 51.08a.57.57 0 00-.59.58v16.57a.57.57 0 00.59.58h15.11a.57.57 0 00.58-.58V51.66a.57.57 0 00-.58-.58z"
        />
        <path
          style={{ stroke: "white" }}
          className="prefix__cls-4"
          d="M83.51 123.7V35.14A1.52 1.52 0 0082 33.58H17"
        />
        <circle className="prefix__cls-4" cx={58.25} cy={58.46} r={3.75} />
      </g>
      <path
        style={{ stroke: "white" }}
        className="prefix__cls-4"
        d="M19.72 91.15l45.97.16M19.72 82.07l45.97.15M19.72 72.99l18.75.15M19.72 54.83l13.89.15M51.8 109.31l13.89.16M19.72 100.23l28.99.16"
      />
      <g className="prefix__cls-8">
        <path
          style={{ stroke: "white" }}
          className="prefix__cls-4"
          d="M64.6 68.81a6.66 6.66 0 00-6.34-6.05 6.61 6.61 0 00-4.65 2.41 8.93 8.93 0 00-1.71 3.53"
        />
        <circle className="prefix__cls-4" cx={229.92} cy={55.4} r={6.38} />
        <path
          style={{ stroke: "white" }}
          className="prefix__cls-4"
          d="M223.55 77.28s-.92 41.55-.92 43.29a3.14 3.14 0 006.28 0l1-29.48s2.23 27.74 2.23 29.48a3.14 3.14 0 006.27 0s-.65-35.19-1-48.3"
        />
      </g>
      <path
        className="prefix__cls-4"
        d="M229.92 91.1v1.29"
        style={{ stroke: "white" }}
      />
      <g className="prefix__cls-8">
        <path
          style={{ stroke: "white" }}
          className="prefix__cls-4"
          d="M217.08 77.35c-.05.58.07 8.89.07 10.62a3.14 3.14 0 003.13 3.14 3 3 0 003.11-2.93v-1.11M237.54 72.32c2 1.35 6.48 4.18 6.74 4.35a2.69 2.69 0 002.56.25c2-1.19 4.79-4.19 5.88-5.4a3 3 0 00.6-2.1 2.33 2.33 0 00-2.33-2 2.7 2.7 0 00-1.34.44s-1.7 1.2-2.73 1.79-2.38-.25-2.64-.42-3.82-2.75-5.95-3.93a7.28 7.28 0 00-3.36-.86c-2.89-.07-7.66-.16-11.26 0a6.72 6.72 0 00-6.63 6.86v5.84M247.63 36.53V51.7c0 .43 14.32-15.25 14.57-15.25h12.72s4.09 0 4.09-4.09v-20s0-4.09-4.09-4.09h-34.8S236 8.3 236 12.39v20s0 4.09 4.09 4.09l7.51.08"
        />
      </g>
      <path
        style={{ stroke: "white" }}
        className="prefix__cls-4"
        d="M244.73 18.87h25.58M244.73 25.42h14.6M263.22 25.42h7.09"
      />
      <g className="prefix__cls-8">
        <circle
          className="prefix__cls-4"
          cx={436.3}
          cy={57.15}
          r={6.6}
          transform="rotate(-24.41 436.235 57.165)"
          style={{ stroke: "white" }}
        />
        <path
          style={{ stroke: "white" }}
          className="prefix__cls-4"
          d="M431.67 61.9c-.91-.81-8.69-7.28-9-8.44s-1.72-6.17-2.11-7.67c-.52-2-1.37-2.75-2.95-2.7a2.84 2.84 0 00-2.76 2.9 2.62 2.62 0 00.17.85c.41 1.12 2.25 7.71 2.54 8.73a9.14 9.14 0 001.9 3.21c1.16 1.44 5.89 5.37 6.27 5.75a4 4 0 011.31 2.27c.15 1.9.15 11.37.15 14.58 0 0-.48 37.28-.48 39.08a3.67 3.67 0 007.29 0l1.8-30.85s2 29.05 2 30.85a3.68 3.68 0 007.3 0s-1-43.65-1.43-49a8.36 8.36 0 00-6.55-7.6"
        />
        <circle
          style={{ stroke: "white" }}
          className="prefix__cls-4"
          cx={393.13}
          cy={57.15}
          r={6.6}
          transform="rotate(-69.18 393.15 57.143)"
        />
        <path
          style={{ stroke: "white" }}
          className="prefix__cls-4"
          d="M397.76 61.9c.91-.81 8.69-7.28 9-8.44s1.72-6.17 2.11-7.67c.52-2 1.37-2.75 2.95-2.7a2.84 2.84 0 012.76 2.9 2.62 2.62 0 01-.17.85c-.41 1.12-2.25 7.71-2.54 8.73a9.14 9.14 0 01-1.9 3.21c-1.16 1.44-5.89 5.37-6.27 5.75a4 4 0 00-1.31 2.27c-.15 1.9-.15 11.37-.15 14.58 0 0 .48 37.28.48 39.08a3.67 3.67 0 01-7.29 0l-1.54-30.68s-2.24 28.88-2.24 30.68a3.68 3.68 0 01-7.3 0s1-43.65 1.43-49a8.36 8.36 0 016.55-7.6"
        />
      </g>
      <path
        style={{ stroke: "white" }}
        className="prefix__cls-4"
        d="M414.81 22.21v15.14M428.81 32.38l-6.57 7.17M400.81 32.38l6.58 7.17M85.51 78.64h119.01M254.81 69.83h119.01"
      />
    </svg>
  );
}

export default KandidatMobile;
