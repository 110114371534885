import React, { useEffect } from "react";
import "./index.css";
import MaxerlRed from "../MaxerlRed";
import { MDBBtn } from "mdbreact";
import { FaLinkedinIn, FaXing } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import MaxerlWhite from "../MaxerlWhite";
import { NavHashLink } from "react-router-hash-link";

export default function StellenangeboteScreen(props) {

  useEffect(() => {
    const currentPage = props.location.pathname;

    props.trackPage(currentPage);
  }, [props]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.zohocdn.com/recruit/embed_careers_site/javascript/v1.0/embed_jobs.4e4301f2a1689cffad29524dc9218188.js";
    script.async = true;
    document.getElementById("rec_job_listing_div").appendChild(script);
    script.onload = () => {
      // eslint-disable-next-line
      eval("rec_embed_js.load({ widget_id: \"rec_job_listing_div\", page_name: \"Careers\", source: \"CareerSite\", site: \"https://karin-weiss.zohorecruit.eu\", empty_job_msg: \"No current Openings\"});");
    };

    window.addEventListener("scroll", () => {
//      console.log(window.scrollY);
      let minus = 1050;
      if (document.body.clientHeight > 1340) {
        minus = 1300;
      }
      if (document.body.clientHeight > 1400) {
        minus = 1320;
      }
      if (window.scrollY > document.body.clientHeight - minus) {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "180px";
          redLogo.style.marginRight = "-65px";
        }
      } else {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "90px";
        }
      }
    });
  }, []);
  return (
    <div id="all">
      <div className="allContainer">
        <div className="mainContainerUnt">
          <div class="embed_jobs_head embed_jobs_with_style_1 embed_jobs_with_style">
            <div class="embed_jobs_head2">
              <div class="embed_jobs_head3">
                <div id="rec_job_listing_div"> </div>
              </div>
            </div>
          </div>

          <div className="footerUnternehmen" id="footerDesktop">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>

            <div className="right">
              <MaxerlRed width="150px" className="redLogoUnternehmen" />
              <MaxerlRed
                width="150px"
                className="redLogoUnternehmenFixed"
                style={{ marginRight: -65 }}
              />
              <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
              <p>
                Mag.a Karin Weiss
                <br /> +43 650 49 789 64
                <br />{" "}
                <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
                <br />
                <br />
                <NavHashLink to="/impressum#root">Impressum</NavHashLink>
                <NavHashLink to="/agbs#root">AGBs</NavHashLink>
                <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
              </p>
            </div>
          </div>
          <div className="footerUnternehmen" id="footerMobile">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="right" id="mobileRight">
        <MaxerlRed width="150px" className="redLogoUnternehmen" />
        <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
        <p>
          Mag.a Karin Weiss
          <br /> +43 650 49 789 64
          <br />{" "}
          <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
          <br />
          <br />
          <NavHashLink to="/impressum#root">Impressum</NavHashLink>
          <NavHashLink to="/agbs#root">AGBs</NavHashLink>
          <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
        </p>
      </div>
      <div className="whiteUnder"></div>
    </div>
  );
}
