import React, { useEffect } from "react";
import "./index.css";
import KandidatenLogo from "../KandidatenLogo";
import { MDBBtn } from "mdbreact";
import { FaLinkedinIn, FaXing } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { NavHashLink } from "react-router-hash-link";
import JobIcon from "../JobIcon";
import MaxerlRed from "../MaxerlRed";
import MaxerlWhite from "../MaxerlWhite";
import KandidatMobile from "../KandidatMobile";

export default function KandidatenScreen(props) {
  useEffect(() => {
    const page = props.location.pathname;
    props.trackPage(page);
  }, [props]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      //      console.log(window.scrollY);
      let minus = 1050;
      if (document.body.clientHeight < 2800) {
        minus = 1300;
      }
      if (document.body.clientHeight < 2900) {
        minus = 1350;
      }
      if (window.scrollY > document.body.clientHeight - minus) {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "180px";
          redLogo.style.marginRight = "-65px";
        }
      } else {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "90px";
        }
      }
    });
  }, []);

  return (
    <div id="all">
      <div className="allContainer">
        <div className="mainContainerUnt">
          <div className="orangeContainer">
            <div className="kandImgBox" id="desktopKandImg"></div>
            <div className="kandText">
              <p>
                Sie sind bereit f&uuml;r den n&auml;chsten Schritt?{" "}
                <strong style={{ fontWeight: 600 }}>
                  Dann sind Sie bei mir richtig.
                </strong>
                <br />
                <br /> Für mich sind Sie keine Nummer –{" "}
                <strong style={{ fontWeight: 600 }}>
                  ich begleite Sie w&auml;hrend des gesamten Recruitingprozesses
                </strong>{" "}
                und bin Ihre pers&ouml;nliche Ansprechpartnerin.
              </p>
            </div>
            <div className="kandImgBox" id="mobileKandImg">
              <p>
                Lassen Sie uns
                <br /> gemeinsam den Job
                <br /> finden, in dem Sie sich
                <br /> entfalten k&ouml;nnen.
              </p>
            </div>
            <div className="kandOverImgText" id="desktopKandText">
              <p>
                Lassen Sie uns
                <br /> gemeinsam den Job
                <br /> finden, in dem Sie sich
                <br /> entfalten k&ouml;nnen.
              </p>
            </div>
            <div className="bewerbung" id="bewerbung">
              <h2>Bewerbungstraining</h2>
              <p>
                Sie wollen sich beruflich ver&auml;ndern? Ob Neu- orientierung
                oder einfach ein neuer Arbeitsplatz – ich unterst&uuml;tze Sie.
                Wir legen den{" "}
                <strong style={{ fontWeight: 600 }}>
                  Fokus auf Ihren USP und wecken so die Aufmerksamkeit der
                  Arbeitgeber*innen!
                </strong>
              </p>

              <div className="bewerbungsText">
                <div className="bText">
                  <h4>Modul Lebenslauf-Check</h4>
                  <div className="heraus" id="desktopHeraus">
                    <JobIcon width="110px" style={{ marginBottom: "50px" }} />
                    <p>
                      <strong style={{ fontWeight: 600 }}>
                        Sie sind auf der Suche nach einer neuen Herausforderung?
                      </strong>
                      <br />
                      <br />
                      Werfen Sie einen Blick auf die offenen Stellenangebote
                      oder schicken Sie mir Ihre Initiativbewerbung. Gerne nehme
                      ich Sie in meine Datenbank auf und kontaktiere Sie, wenn
                      ich eine passende Stellenausschreibung in meinem Portfolio
                      habe:
                    </p>
                    <div className="buttonWrapper">
                      <NavHashLink to="/stellenangebote#root">
                        <MDBBtn color="red">Stellenangebote</MDBBtn>
                      </NavHashLink>
                      <MDBBtn
                        color="red"
                        onClick={() =>
                          (window.location.href =
                            "mailto:office@karin-weiss.at?subject=Initiativbewerbung")
                        }
                      >
                        Initiativbewerbung
                      </MDBBtn>
                    </div>

                    <p>
                      Sie können mich auch anrufen oder mir ein E-Mail
                      schreiben: <br />
                      <br /> +43 650 49 789 64
                      <br />{" "}
                      <a
                        className="mailHover"
                        href="mailto:office@karin-weiss.at"
                      >
                        office@karin-weiss.at
                      </a>
                    </p>
                  </div>
                  <p>
                    Nachdem ich Ihren Lebenslauf analysiert habe, erarbeiten wir
                    gemeinsam eine aussagekr&auml;ftige Übersicht über Ihre
                    bisherige Laufbahn.
                  </p>
                  <h4 style={{ marginTop: 40 }}>
                    Modul Bewerbungsberatung und -betreuung
                  </h4>
                  <p>
                    Ich begleite Sie auf Ihrem Bewerbungsweg bis hin zur
                    erfolgreichen Vertragsunzeichnung:
                    <br />
                    <ul className="specialUl">
                      <li>Erstellung eines Qualifikationsprofils</li>
                      <li>
                        Erarbeitung Ihrer Ziele und realistischer
                        Berufsperspektiven
                      </li>
                      <li>
                        Interview-Vorbereitung und Feedback für einen
                        authentischen Auftritt
                      </li>
                      <li>
                        Betreuung bis zum Finden eines neuen Arbeitsplatzes
                      </li>
                    </ul>
                  </p>
                </div>
                <div className="handyIcon">
                  <KandidatMobile width="400px" id="kandMobileLogo" />
                </div>
                <div className="bIcon">
                  <KandidatenLogo width="110px" />
                </div>
              </div>
            </div>
          </div>
          <div className="heraus" id="mobileHeraus">
            <p>
              Sie sind auf der Suche nach einer neuen Herausforderung?
              <br />
              <br />
              Werfen Sie einen Blick auf die offenen Stellenangebote oder
              schicken Sie mir Ihre Initiativbewerbung. Gerne nehme ich Sie in
              meine Datenbank auf und kontaktiere Sie, wenn ich eine passende
              Stellenausschreibung in meinem Portfolio habe:
            </p>

            <div className="buttonWrapper">
              <NavHashLink to="/stellenangebote#root">
                <MDBBtn color="white">Stellenangebote</MDBBtn>
              </NavHashLink>
              <MDBBtn
                color="white"
                onClick={() =>
                  (window.location.href =
                    "mailto:office@karin-weiss.at?subject=Initiativbewerbung")
                }
              >
                Initiativbewerbung
              </MDBBtn>
            </div>

            <p>
              Sie können mich auch anrufen oder mir ein E-Mail schreiben:
              <br />
              <br /> +43 650 49 789 64
              <br />{" "}
              <a href="mailto:office@karin-weiss.at" className="mailHoverWhite">
                office@karin-weiss.at
              </a>
            </p>
          </div>
          <div className="orangeContainer2">
            <div className="kandCoaching" id="coaching">
              <div className="imgBox3" id="desktopImgBox3"></div>
              <h2>Coaching</h2>
              <p>
                Mit Coaching kann ich Sie unterstützen,{" "}
                <strong style={{ fontWeight: 600 }}>
                  wenn Sie sich und Ihre derzeitige Situation verändern oder
                  entwicklen wollen
                </strong>{" "}
                – sei es, weil es beruflich für Sie nicht rund l&auml;uft oder
                weil Sie sich weiterentwickeln und Ihr Potential entfalten
                möchten.
                <br />
                <br />
              </p>
              <p>
                <strong style={{ fontWeight: 600, paddingBottom: 20 }}>
                  Mit Coaching kann ich Ihnen erm&ouml;glichen:
                </strong>
                <br />
                <ul style={{ marginTop: 10, paddingBottom: 15 }}>
                  <li>Ihre Situation zu reflektieren</li>
                  <li>Konflikte zu erkennen und L&ouml;sungen zu finden</li>
                  <li>neue Erkenntnisse nachhaltig umzusetzen</li>
                  <li>
                    Sicherheit zu erlangen, Ihre Kommunikation zu verbessern und
                    Ihre soziale Kompetenz zu steigern
                  </li>
                  <li>Sie für zuk&uuml;nftige Herausforderungen zu rüsten</li>
                  <li>
                    den Wechsel von Kolleg*in zur Führungspersönlichkeit
                    erfolgreich zu meistern
                  </li>
                </ul>

                <strong style={{ fontWeight: 600 }}>
                  Kontaktieren Sie mich und erfahren Sie mehr!
                </strong>
              </p>
            </div>
            <div className="imgBox3" id="mobileImgBox3"></div>
          </div>
          <div className="footerUnternehmen" id="footerDesktop">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>

            <div className="right">
              <MaxerlRed width="150px" className="redLogoUnternehmen" />
              <MaxerlRed
                width="150px"
                className="redLogoUnternehmenFixed"
                style={{ marginRight: -65 }}
              />
              <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
              <p>
                Mag.a Karin Weiss
                <br /> +43 650 49 789 64
                <br />{" "}
                <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
                <br />
                <br />
                <NavHashLink to="/impressum#root">Impressum</NavHashLink>
                <NavHashLink to="/agbs#root">AGBs</NavHashLink>
                <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
              </p>
            </div>
          </div>
          <div className="footerUnternehmen" id="footerMobile">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="right" id="mobileRight">
        <MaxerlRed width="150px" className="redLogoUnternehmen" />
        <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
        <p>
          Mag.a Karin Weiss
          <br /> +43 650 49 789 64
          <br />{" "}
          <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
          <br />
          <br />
          <NavHashLink to="/impressum#root">Impressum</NavHashLink>
          <NavHashLink to="/agbs#root">AGBs</NavHashLink>
          <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
        </p>
      </div>
      <div className="whiteUnder"></div>
    </div>
  );
}
