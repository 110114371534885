import * as React from "react";

function JobIcon(props) {
  return (
    <svg id="jobIcone" data-name="Ebene 65" viewBox="0 0 84 74" {...props}>
      <defs>
        <clipPath id="prefix__clip-path-job">
          <path fill="none" d="M9.14 8.51h65.72v56.98H9.14z" />
        </clipPath>
        <style>
          {
            ".prefix__cls-120{fill:none}.prefix__cls-160{clip-path:url(#prefix__clip-path-job)}.prefix__cls-120{stroke:#1d1d1b;stroke-linecap:round}.prefix__cls-170{fill:#1d1d1b}"
          }
        </style>
      </defs>
      <g className="prefix__cls-160">
        <path
          className="prefix__cls-120"
          style={{
            stroke: "#1d1d1b",
            strokeLinecap: "round",
            fill: "none",
            strokeWidth: 1,
          }}
          d="M74.36 58.43V15.17S74.36 9 68.2 9H15.8s-6.16 0-6.16 6.17v39.75s0 6.16 6.16 6.16l46.45.22"
        />
        <circle
          className="prefix__cls-120"
          cx={55.34}
          cy={46.9}
          r={10.07}
          style={{
            stroke: "#1d1d1b",
            strokeLinecap: "round",
            fill: "none",
            strokeWidth: 1,
          }}
        />
      </g>
      <path
        className="prefix__cls-120"
        d="M62.39 54.13l3.27 3.09"
        style={{
          stroke: "#1d1d1b",
          strokeLinecap: "round",
          fill: "none",
          strokeWidth: 1,
        }}
      />
      <g className="prefix__cls-160">
        <path
          className="prefix__cls-120"
          style={{
            stroke: "#1d1d1b",
            strokeLinecap: "round",
            fill: "none",
            strokeWidth: 1,
          }}
          d="M71.13 64.59a1.38 1.38 0 101.93-2s-4.82-4.95-5.37-5.48a1.38 1.38 0 10-1.93 2c.54.53 5.37 5.48 5.37 5.48z"
        />
      </g>
      <path
        className="prefix__cls-120"
        style={{
          stroke: "#1d1d1b",
          strokeLinecap: "round",
          fill: "none",
          strokeWidth: 1,
        }}
        d="M17.44 39.71l11.3.11M17.44 44.44l20.95.11M17.44 49.17l20.95.11M17.44 18.63l48.66.1M17.44 53.9l20.95.11"
      />
      <g className="prefix__cls-160">
        <path
          className="prefix__cls-170"
          style={{ fill: "#1d1d1b", stroke: "none", strokeWidth: 1 }}
          d="M52.07 31.71h-2.36v-2.57h2.4c1 0 1.55.44 1.55 1.27s-.52 1.3-1.59 1.3M52 25.79c.9 0 1.33.38 1.33 1.06S52.87 28 51.9 28h-2.19v-2.21zm1.47 2.64a1.8 1.8 0 001.34-1.79c0-1.27-1-2-2.58-2h-4v8.31h4c1.86 0 2.86-1 2.86-2.43a2 2 0 00-1.67-2.06m-12.08 3.45c-1.59 0-2.63-1.24-2.63-3.16s1-3.08 2.63-3.08S44 26.89 44 28.75s-1.07 3.16-2.65 3.16m0 1.24a4.1 4.1 0 004.15-4.4 4.13 4.13 0 10-8.26 0 4.08 4.08 0 004.11 4.4m-9.5-.07a2.32 2.32 0 002.64-2.47V25H33v5.6c0 .83-.38 1.24-1.17 1.24a1.09 1.09 0 01-1.25-1.24V30h-1.44v.59c0 1.54 1 2.46 2.69 2.46"
        />
      </g>
      <path
        strokeWidth={1.48}
        stroke="#1d1d1b"
        strokeLinecap="round"
        fill="none"
        d="M33.74 24.84v1.3"
      />
      <path
        strokeWidth={1.43}
        stroke="#1d1d1b"
        strokeLinecap="round"
        fill="none"
        d="M29.85 30.02v.38"
      />
      <g className="prefix__cls-160">
        <path
          style={{ fill: "#1d1d1b", stroke: "none", strokeWidth: 1 }}
          className="prefix__cls-170"
          d="M16.28 15.42A1.28 1.28 0 1015 14.14a1.28 1.28 0 001.28 1.28M21 15.42a1.28 1.28 0 10-1.28-1.28A1.28 1.28 0 0021 15.42M25.63 15.42a1.28 1.28 0 10-1.29-1.28 1.28 1.28 0 001.29 1.28"
        />
      </g>
    </svg>
  );
}

export default JobIcon;
