import React from "react";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBBtn,
} from "mdbreact";

import { NavHashLink } from "react-router-hash-link";

const CarouselPage = () => {
  return (
    <div className="carousel-imgs">
      <MDBCarousel
        activeItem={1}
        length={2}
        showControls={false}
        showIndicators={false}
        className="z-depth-1"
        slide
        interval={3500}
      >
        <MDBCarouselInner>
          <MDBCarouselItem itemId="1">
            <MDBView>
              <div className="bigImgBox">

              <MDBBtn color="red">
                        <NavHashLink to="/stellenangebote#root">
                          Stellenangebote
                        </NavHashLink>
                      </MDBBtn>
              </div>

              <div className="placeholder">

                <h1>
                  Recruiting
                  <br />
                  Personal
                  <br />
                  Coaching
                </h1>
              </div>
            </MDBView>
          </MDBCarouselItem>
          <MDBCarouselItem itemId="2">
            <MDBView>
              <div className="bigImgBox2">
                <div>
                  <h1>
                    Ich freue mich
                    <br />
                    darauf, Sie bei der
                    <br />
                    Erreichung Ihrer Ziele <br />
                    zu unterst&uuml;tzen.
                  </h1>
                  <MDBBtn
                    onClick={() =>
                      (window.location.href = "mailto:office@karin-weiss.at")
                    }
                  >
                    Anfrage
                  </MDBBtn>
                </div>
              </div>
            </MDBView>
          </MDBCarouselItem>
        </MDBCarouselInner>
      </MDBCarousel>
    </div>
  );
};

export default CarouselPage;
