import React from "react";
import "./index.css";
import { MDBNav, MDBNavItem } from "mdbreact";
import Logo from "../Logo";

import { NavHashLink } from "react-router-hash-link";

export default function Navigator() {
  const scrollCoaching = () => {
    const id = "coaching";
    const yOffset = -200;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    console.log(y);
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const scrollRecruiting = () => {
    const id = "recruiting";
    const yOffset = -200;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    console.log(y);
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const scrollBewerbung = () => {
    const id = "bewerbung";
    const yOffset = -180;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const scrollKandCoaching = () => {
    const id = "coaching";
    const yOffset = -180;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <div className="navMain" id="top">
      <div className="navLeft">
        <MDBNav className="justify-content-end">
          <MDBNavItem>
            <NavHashLink
              to="/unternehmen#root"
              activeClassName="activeLink"
              className="topLinks"
            >
              Unternehmen
            </NavHashLink>
            <div className="subLinks">
              <li className="links">
                <NavHashLink
                  onClick={() => scrollRecruiting()}
                  to="/unternehmen"
                  className="specialA"
                >
                  Recruiting
                </NavHashLink>
              </li>
              <li className="links">
                <NavHashLink
                  to="/unternehmen"
                  className="specialA"
                  onClick={() => scrollCoaching()}
                >
                  Coaching
                </NavHashLink>
              </li>
            </div>
          </MDBNavItem>
          <MDBNavItem>
            <NavHashLink
              to="/kandidatInnen#root"
              activeClassName="activeLinkKand"
              className="topLinks"
            >
              Kandidat*innen
            </NavHashLink>
            <div className="subLinks">
              <li className="links">
                <NavHashLink
                  to="/kandidatInnen"
                  onClick={() => scrollBewerbung()}
                  className="specialA"
                >
                  Bewerbung
                </NavHashLink>
              </li>
              <li className="links">
                <NavHashLink
                  to="/kandidatInnen"
                  onClick={() => scrollKandCoaching()}
                  className="specialA"
                >
                  Coaching
                </NavHashLink>
              </li>
            </div>
          </MDBNavItem>
          <MDBNavItem>
            <NavHashLink
              to="/karinweiss#root"
              activeClassName="activeLinkKarin"
              className="topLinks special"
            >
              Karin Weiss
            </NavHashLink>
            <div className="subLinks"></div>
          </MDBNavItem>
        </MDBNav>
      </div>
      <div className="navRight">
        <MDBNav>
          <MDBNavItem className="navSpecial">
            <NavHashLink
              to="/kontakt#root"
              activeClassName="activeLinkRight"
              className="topLinks"
            >
              Kontakt
            </NavHashLink>
            <div className="subLinks"></div>
          </MDBNavItem>
        </MDBNav>
        <MDBNav>
          <MDBNavItem className="logo">
            <NavHashLink to="/#root">
              <Logo width="200px" />
            </NavHashLink>
            <div className="subLinks"></div>
          </MDBNavItem>
        </MDBNav>
      </div>
    </div>
  );
}
