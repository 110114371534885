import * as React from "react";

function MobileUnternehmen(props) {
  return (
    <svg
      id="prefix__Ebene_1"
      data-name="Ebene 1"
      viewBox="0 0 510 109"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path">
          <path fill="none" d="M10.65 8.44h488.7v91.12H10.65z" />
        </clipPath>
        <style>
          {
            ".prefix__cls-3,.prefix__cls-4{fill:none}.prefix__cls-2{clip-path:url(#prefix__clip-path)}.prefix__cls-3,.prefix__cls-4{stroke:#fff;stroke-width:1px}.prefix__cls-3{stroke-linecap:round}"
          }
        </style>
      </defs>
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <path
          className="prefix__cls-9"
          style={{ stroke: "white" }}
          d="M25.78 95.23l14.03 2.48M25.37 95.26l-13.72 2.42"
        />
        <circle
          className="prefix__cls-4"
          style={{ strokeWidth: "1px" }}
          cx={32.58}
          cy={31.28}
          r={7.21}
        />
        <path
          className="prefix__cls-9"
          style={{ stroke: "white" }}
          d="M25.17 37.74a16.86 16.86 0 00-4.09 8.09c-1 5-3.07 13.52-3.07 18.43s4.1 8.23 9 8.89c2.21.3 7.47.7 10.4.93 2.73.21 2.63 1.68 2.42 2.94S37 91.2 36.78 93.86a3.81 3.81 0 003.94 3.93 3.94 3.94 0 003.74-2.69c.13-.39 4-20.13 4-20.13.55-3-1.34-6.14-5.37-7L29 65"
        />
        <path
          className="prefix__cls-9"
          style={{ stroke: "white" }}
          d="M12.47 37.43s.13 20.14.13 27.57 5.64 12.38 13.6 13.37c4.84.6 8.74.92 8.74.92"
        />
        <path
          className="prefix__cls-4"
          style={{ strokeWidth: "1px" }}
          d="M12.47 64.95V37.43"
        />
      </g>
      <path
        className="prefix__cls-9"
        d="M25.37 78.37v16.4"
        style={{ stroke: "white" }}
      />
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <path
          className="prefix__cls-9"
          style={{ stroke: "white" }}
          d="M49.07 61.28a3.17 3.17 0 003.12-3.22A3.24 3.24 0 0049 54.8H37.32M34.1 40.91s.11 8.86.11 10.64a3.17 3.17 0 003.12 3.22"
        />
      </g>
      <path
        className="prefix__cls-9"
        d="M49.01 61.27H36.43"
        style={{ stroke: "white" }}
      />
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <circle
          className="prefix__cls-4"
          style={{ strokeWidth: "1px" }}
          cx={98.66}
          cy={31.28}
          r={7.21}
        />
        <path
          className="prefix__cls-9"
          style={{ stroke: "white" }}
          d="M106.06 37.74a16.83 16.83 0 014.1 8.09c1 5 3.06 13.52 3.06 18.43s-4.09 8.23-9 8.89c-2.2.3-7.46.7-10.39.93-2.73.21-2.63 1.68-2.42 2.94s2.84 14.18 3.1 16.84a3.81 3.81 0 01-3.94 3.93 3.93 3.93 0 01-3.74-2.69c-.13-.39-4-20.13-4-20.13-.56-3 1.33-6.14 5.37-7l14.13-3"
        />
        <path
          className="prefix__cls-9"
          style={{ stroke: "white" }}
          d="M118.77 37.43s-.13 20.14-.13 27.52S113 77.33 105 78.32c-4.85.6-8.74.92-8.74.92"
        />
      </g>
      <path
        className="prefix__cls-4"
        style={{ strokeWidth: "1px" }}
        d="M118.77 64.95V37.43"
      />
      <path
        className="prefix__cls-9"
        style={{ stroke: "white" }}
        d="M105.87 78.37v16.4"
      />
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <path
          className="prefix__cls-9"
          style={{ stroke: "white" }}
          d="M105.46 95.23l-14.04 2.48M105.87 95.26l13.72 2.42M82.17 61.28a3.17 3.17 0 01-3.12-3.22 3.24 3.24 0 013.15-3.26h11.68M97.14 40.91S97 49.77 97 51.55a3.17 3.17 0 01-3.12 3.22"
        />
      </g>
      <path
        className="prefix__cls-9"
        style={{ stroke: "white" }}
        d="M82.22 61.27h12.59M47.74 61.25h35.7M89.4 19.87l-5.04-6.72M86.72 24.7H79.1"
      />
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <path
          className="prefix__cls-9"
          style={{ stroke: "white" }}
          d="M95.12 18.4l.93-8.19M65.17 97.68V62.17"
        />
        <ellipse
          className="prefix__cls-9"
          style={{ stroke: "white" }}
          cx={246.74}
          cy={41.08}
          rx={29.86}
          ry={29.85}
        />
      </g>
      <path
        className="prefix__cls-9"
        style={{ stroke: "white", fill: "none" }}
        d="M267.66 62.52l9.69 9.16"
      />
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <path
          className="prefix__cls-9"
          style={{ stroke: "white", fill: "none" }}
          d="M293.55 93.54a4.1 4.1 0 005.74-5.85S285 73 283.37 71.43a4.1 4.1 0 10-5.74 5.85c1.61 1.58 15.92 16.26 15.92 16.26zM247.47 17.7s-1-2.83-2 0l-2.1 5.81a4.54 4.54 0 01-4 2.9l-6.37.16s-3 .08-.6 1.88l5 3.79a4.45 4.45 0 011.52 4.68l-1.78 5.91s-.87 2.87 1.62 1.19l5.24-3.53a4.62 4.62 0 015 0l5.16 3.51s2.48 1.68 1.61-1.19l-1.49-5a5.93 5.93 0 012-6.23l5-3.79s1.6-1.2-.4-1.25l-5.37-.14s-5-.12-6.69-4.83zM232.28 62.52s-.1-2.46-.1-5.4a9.41 9.41 0 019.41-9.41h10.24a9.41 9.41 0 019.41 9.41c0 2.94-.1 5.4-.1 5.4M215.43 98.56s-.07-1.87-.07-4.1a7.17 7.17 0 017.19-7.15H230.03a7.17 7.17 0 017.2 7.15c0 2.23-.08 4.1-.08 4.1M279.35 66.35s-.07-1.87-.07-4.1a7.17 7.17 0 017.19-7.15H293.95a7.17 7.17 0 017.2 7.15c0 2.23-.08 4.1-.08 4.1M277.33 26.14a7.23 7.23 0 013.88-1.14h7.49a7.17 7.17 0 017.19 7.15c0 2.23-.08 4.1-.08 4.1M237.39 98.56s-.08-1.87-.08-4.1a7.18 7.18 0 017.2-7.15H251.99a7.17 7.17 0 017.19 7.15c0 2.23-.07 4.1-.07 4.1M267.93 87.29h-1.46a7.17 7.17 0 00-7.2 7.15c0 2.23.08 4.1.08 4.1M272.49 87.29h1.46a7.17 7.17 0 017.19 7.15c0 2.23-.07 4.1-.07 4.1"
        />
      </g>
      <path
        className="prefix__cls-9"
        style={{ stroke: "white", fill: "none" }}
        d="M246.29 60.87v1.8M246.29 55.86v1.8M246.29 50.84v1.81"
      />
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <circle
          className="prefix__cls-9"
          style={{ stroke: "white", fill: "none" }}
          cx={226.29}
          cy={77.79}
          r={6.36}
        />
        <circle
          className="prefix__cls-9"
          style={{ stroke: "white", fill: "none" }}
          cx={290.21}
          cy={45.58}
          r={6.36}
          transform="rotate(-14.04 290.143 45.58)"
        />
        <circle
          className="prefix__cls-9"
          style={{ stroke: "white", fill: "none" }}
          cx={284.19}
          cy={15.8}
          r={6.36}
          transform="rotate(-14.87 284.227 15.815)"
        />
        <path
          className="prefix__cls-9"
          style={{ stroke: "white", fill: "none" }}
          d="M242.9 74.36a6.36 6.36 0 1010.62-.13M268.2 71.76a6.36 6.36 0 107.34 9.51M413.64 25.11a7.32 7.32 0 10-7.32-7.32 7.32 7.32 0 007.32 7.32zM456.35 24.62A7.32 7.32 0 10449 17.3a7.32 7.32 0 007.35 7.32zM414 31.6s3.54 8.74 4.26 9.95a15.3 15.3 0 003.23 3.52c.55.4 8.62 5.37 10.32 6.39a3.59 3.59 0 004.94-1.23 3.75 3.75 0 00-1.23-4.94S431 43 430.8 43c0 0-4.4-2.26-5.67-3a6.06 6.06 0 01-2.35-2.67c-1.18-2.46-2.33-6.86-3.2-8.37a8.26 8.26 0 00-6.75-3.49c-4.61 0-8.05 3.83-8.36 8.53-.14 2.27-.38 11.5-.62 22.08"
        />
        <path
          className="prefix__cls-9"
          style={{ stroke: "white", fill: "none" }}
          d="M449.27 43.84s-1.06 47.69-1.06 49.68a3.6 3.6 0 007.2 0l1.16-33.82s2.56 31.83 2.56 33.82a3.6 3.6 0 107.2 0s-1-54.45-1.4-60.45c-.31-4.7-3.75-8.53-8.36-8.53a8.26 8.26 0 00-6.75 3.46c-.87 1.51-2 5.91-3.2 8.37a6.06 6.06 0 01-2.35 2.67c-1.27.76-5.67 3-5.67 3"
        />
        <path
          className="prefix__cls-9"
          style={{ stroke: "white", fill: "none" }}
          d="M456.57 31.6s-5.35 9.5-5.93 10.28a17.84 17.84 0 01-3.53 2.94c-1 .59-10.93 6.26-10.93 6.26M395.82 60.87h46.11a1.35 1.35 0 001.38-1.38v-2.1a1.35 1.35 0 00-1.38-1.39h-38.05M426.28 60.83l9.04 36.02M466.25 70.51s6.56-.08 7.83-.08a2.3 2.3 0 002.29-2.07s2.69-20.64 3-22.48a4.85 4.85 0 011-2.22 5.05 5.05 0 013.81-1.91s10.46.26 11.41.33a3 3 0 012.52 1.43 2.52 2.52 0 01.25 1.56c-.18 1.28-3 24.89-3 24.89-.37 2.05-1.69 4.14-3.55 4.24-1.55.08-25.14.18-25.14.18"
        />
      </g>
      <path
        className="prefix__cls-9"
        style={{ stroke: "white", fill: "none" }}
        d="M471.97 70.46h12.73M480.5 78.78v15.84M474.63 94.62h11.95"
      />
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <path
          className="prefix__cls-9"
          style={{ stroke: "white", fill: "none" }}
          d="M472 96.74a2.12 2.12 0 10-2.12-2.12 2.12 2.12 0 002.12 2.12zM489 96.74a2.12 2.12 0 10-2.12-2.12 2.12 2.12 0 002.12 2.12z"
        />
      </g>
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <path
          className="prefix__cls-9"
          style={{ stroke: "white", fill: "none" }}
          d="M403.65 65.23c-.31 14.8-.58 29.19-.58 29.19a3.6 3.6 0 107.2 0c0-2 2.49-30.12 2.49-30.12L414 94.42a3.6 3.6 0 007.2 0c0-1.07-.3-15.72-.57-29"
        />
      </g>
      <path
        className="prefix__cls-9"
        style={{ stroke: "white", fill: "none" }}
        d="M403.88 56.01H311"
      />
      <g className="prefix__cls-1" style={{ fill: "none", stroke: "white" }}>
        <path className="prefix__cls-9" d="M420.42 56.06l-.26-12.16" />
      </g>
      <path
        className="prefix__cls-9"
        style={{ stroke: "white", fill: "none" }}
        d="M456.57 59.7v1.48M412.74 64.48v.79M118.99 37.85H208"
      />
    </svg>
  );
}

export default MobileUnternehmen;
