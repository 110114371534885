import * as React from "react";

function KandidatenLogo(props) {
  return (
    <svg id="kandototos" data-name="Ebene 1" viewBox="0 0 84 314" {...props}>
      <defs>
        <clipPath id="prefix__clip-path-120">
          <path fill="none" d="M7.21 7.99h67.58v298.02H7.21z" />
        </clipPath>
        <style>
          {
            ".prefix__cls-3,.prefix__cls-4{fill:none}.prefix__cls-2{clip-path:url(#prefix__clip-path-120)}.prefix__cls-3,.prefix__cls-4{stroke:#fff}.prefix__cls-4{stroke-linecap:round}"
          }
        </style>
      </defs>
      <g className="prefix__cls-2">
        <path
          style={{ fill: "none", strokeWidth: "1px" }}
          className="prefix__cls-3"
          d="M8.8 13.81a1.06 1.06 0 00-1.09 1.08v55A1.06 1.06 0 008.8 71h44.48a1.06 1.06 0 001.09-1.08v-55a1.06 1.06 0 00-1.09-1.08z"
        />
        <path
          style={{ fill: "none", strokeWidth: "1px" }}
          className="prefix__cls-3"
          d="M36.89 20.7a.4.4 0 00-.41.41v11.57a.39.39 0 00.41.4h10.55a.39.39 0 00.4-.4V21.11a.4.4 0 00-.4-.41z"
        />
        <path
          className="prefix__cls-4"
          style={{ fill: "none", strokeWidth: "1px" }}
          d="M59.8 105.93V9.58a1.07 1.07 0 00-1.09-1.09H13.4"
        />
        <path
          className="prefix__cls-4"
          style={{ fill: "none", strokeWidth: "1px" }}
          d="M42.16 28.47a2.62 2.62 0 10-2.62-2.62 2.62 2.62 0 002.62 2.62z"
        />
      </g>
      <path
        className="prefix__cls-4"
        style={{ fill: "none", strokeWidth: "1px" }}
        d="M15.26 48.68l32.09.1M15.26 42.34l32.09.1M15.26 36l13.09.1M15.26 23.32l9.7.11M37.66 61.35l9.69.11M15.26 55.01l20.24.11"
      />
      <g className="prefix__cls-2" style={{ fill: "none", strokeWidth: "1px" }}>
        <path
          className="prefix__cls-4"
          style={{ fill: "none", strokeWidth: "1px" }}
          d="M46.59 33.08a4.64 4.64 0 00-4.43-4.22 4.62 4.62 0 00-3.24 1.68A6.13 6.13 0 0037.73 33M40 153.63a4.45 4.45 0 10-4.45-4.45 4.45 4.45 0 004.45 4.45zM35.58 164.46s-.65 29-.65 30.21a2.19 2.19 0 004.38 0L40 174.1s1.56 19.36 1.56 20.57a2.19 2.19 0 104.38 0s-.46-24.56-.74-33.71"
        />
      </g>
      <path
        className="prefix__cls-4"
        d="M40.02 174.1v.9"
        style={{ fill: "none", strokeWidth: "1px" }}
      />
      <g className="prefix__cls-2" style={{ fill: "none", strokeWidth: "1px" }}>
        <path
          className="prefix__cls-4"
          style={{ fill: "none", strokeWidth: "1px" }}
          d="M31.06 164.5v7.42a2.19 2.19 0 002.19 2.19 2.11 2.11 0 002.17-2.05v-.77M45.34 161c1.42.95 4.53 2.92 4.7 3a1.9 1.9 0 001.79.18 22.88 22.88 0 004.11-3.77 2.1 2.1 0 00.41-1.47 1.62 1.62 0 00-1.62-1.38 1.82 1.82 0 00-.94.31s-1.19.83-1.9 1.25-1.67-.18-1.85-.3-2.66-1.92-4.15-2.74a5 5 0 00-2.35-.61c-2 0-5.34-.1-7.85 0a4.71 4.71 0 00-4.64 4.79v4.08M52.38 136v10.6c0 .3 10-10.64 10.18-10.64h8.87a2.8 2.8 0 002.86-2.86v-13.94a2.8 2.8 0 00-2.86-2.86H47.14a2.8 2.8 0 00-2.86 2.86v13.94a2.8 2.8 0 002.86 2.9h5.24"
        />
      </g>
      <path
        className="prefix__cls-4"
        style={{ fill: "none", strokeWidth: "1px" }}
        d="M50.36 123.68h17.86M50.36 128.26h10.19M63.27 128.26h4.95"
      />
      <g className="prefix__cls-2" style={{ fill: "none", strokeWidth: "1px" }}>
        <circle
          className="prefix__cls-4"
          style={{ fill: "none", strokeWidth: "1px" }}
          cx={51.65}
          cy={259.05}
          r={4.61}
        />
        <path
          className="prefix__cls-4"
          style={{ fill: "none", strokeWidth: "1px" }}
          d="M48.42 262.37c-.63-.56-6.06-5.08-6.27-5.89s-1.2-4.31-1.47-5.35c-.36-1.42-1-1.93-2.06-1.89a2 2 0 00-1.92 2 2.36 2.36 0 00.11.59c.29.78 1.58 5.38 1.78 6.09a6.28 6.28 0 001.32 2.24c.82 1 4.11 3.75 4.38 4a2.84 2.84 0 01.92 1.59c.1 1.32.1 7.94.1 10.18 0 0-.34 26-.34 27.28a2.57 2.57 0 005.1 0l1.25-21.54S52.7 302 52.7 303.25a2.57 2.57 0 005.1 0s-.72-30.47-1-34.24a5.85 5.85 0 00-4.57-5.31"
        />
        <circle
          className="prefix__cls-4"
          style={{ fill: "none", strokeWidth: "1px" }}
          cx={21.52}
          cy={259.05}
          r={4.6}
        />
        <path
          className="prefix__cls-4"
          style={{ fill: "none", strokeWidth: "1px" }}
          d="M24.75 262.37c.64-.56 6.07-5.08 6.27-5.89s1.2-4.31 1.47-5.35c.36-1.42 1-1.93 2.06-1.89a1.94 1.94 0 011.81 2.62c-.28.78-1.57 5.38-1.78 6.09a6.28 6.28 0 01-1.32 2.24c-.81 1-4.11 3.75-4.38 4a2.83 2.83 0 00-.91 1.59c-.1 1.32-.1 7.94-.1 10.18 0 0 .33 26 .33 27.28a2.42 2.42 0 01-2.55 2.26 2.41 2.41 0 01-2.54-2.26L22 281.83s-1.53 20.17-1.53 21.42a2.42 2.42 0 01-2.55 2.26 2.41 2.41 0 01-2.54-2.26s.72-30.47 1-34.24a5.86 5.86 0 014.57-5.31"
        />
      </g>
      <path
        className="prefix__cls-4"
        style={{ fill: "none", strokeWidth: "1px" }}
        d="M36.65 206.93v38.3M46.43 241.76l-4.59 5.01M26.88 241.76l4.59 5.01"
      />
    </svg>
  );
}

export default KandidatenLogo;
