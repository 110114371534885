import React, { useEffect } from "react";
import "./index.css";
import MaxerlRed from "../MaxerlRed";
import MaxerlWhite from "../MaxerlWhite";
import { MDBBtn } from "mdbreact";
import { FaLinkedinIn, FaXing } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { NavHashLink } from "react-router-hash-link";

export default function Datenschutz(props) {

  useEffect(() => {
    const currentPage = props.location.pathname;

    props.trackPage(currentPage);
  }, [props]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
//      console.log(window.scrollY);
      let minus = 1040;
      if (document.body.clientHeight > 6200) {
        minus = 1320;
      }
      if (window.scrollY > document.body.clientHeight - minus) {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "180px";
          redLogo.style.marginRight = "-65px";
        }
      } else {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];
          const redLogoFixed = document.getElementsByClassName(
            "redLogoUnternehmenFixed"
          )[0];
          redLogoFixed.style.display = "none";

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "90px";
        }
      }
    });
  }, []);

  return (
    <div id="all">
      <div className="allContainer">
        <div className="mainContainerUnt">
          <p className="agb">
            <strong style={{ fontWeight: 600, fontSize: "22px" }}>
              Allgemeine Gesch&auml;ftsbedingungen
            </strong>{" "}
            <br />
            <strong style={{ fontWeight: 600 }}>
              I.Allgemeines/Geltungsbereich
            </strong>{" "}
            <br />
            <br />
            1. „Karin Weiss Personal Recruiting Coaching“ - in Folge kurz KW
            genannt - erbringt ihre Personalberatungsleistungen f&uuml;r
            Auftraggeber*innen ausschließlich zu nachstehenden Allgemeinen
            Gesch&auml;ftsbedingungen – in Folge kurz „AGB“ genannt. Die AGB
            gelten in ihrer jeweils zum Vertragsabschluss g&uuml;ltigen Fassung.
            Sofern die nachstehenden AGB dem Vertragsverh&auml;ltnis zugrunde
            gelegt wurden, gelten diese auch im Falle m&uuml;ndlicher
            Auftragserteilung.
            <br />
            <br /> 2. Von den AGB abweichende Vereinbarungen bed&uuml;rfen zu
            ihrer G&uuml;ltigkeit der ausdr&uuml;cklichen schriftlichen
            Best&auml;tigung durch KW.
            <br />
            <br />{" "}
            <strong style={{ fontWeight: 600 }}>
              II. Vertragsgegenstand/Leistungen von KW
            </strong>{" "}
            <br />
            <br />
            1. Art und Umfang der Leistungen von KW ergeben sich aus dem
            jeweiligen Angebot an die Auftraggeber*in. KW versteht sich als
            hochqualifiziertes Beratungsunternehmen, das Dienst- und
            Beratungsleistungen vor allem auf dem Gebiet der Personalsuche und
            -auswahl erbringt. KW f&uuml;hrt die Personalsuche und Beratung der
            Auftraggeber*in bei der Suche nach einer qualifizierten Kandidat*in
            nach den im (m&uuml;ndlichen oder schriftlichen) Angebot
            festgelegten Kriterien durch. Diese Kriterien betreffen insbesondere
            den Aufgabenbereich der k&uuml;nftigen Mitarbeiter*in (Begriffe,
            dessen pers&ouml;nliches und fachliches Anforderungsprofil sowie
            sonstige, f&uuml;r die Personalsuche relevante Kriterien).
            <br />
            <br /> 2. Die Auftraggeber*in nimmt ausdr&uuml;cklich zur Kenntnis,
            dass die Qualit&auml;t der KW Beratungsleistungen und insbesondere
            der qualifizierten Personalsuche erheblich von der Bekanntgabe
            m&ouml;glichst genauer (pers&ouml;nlicher und fachlicher)
            Anforderungsprofile und sonstiger relevanter Bewerber*innenkriterien
            durch die Auftraggeber*in sowie generell von einer engen
            Zusammenarbeit zwischen KW und der Auftraggeber*in im Rahmen des
            Recruitingprozesses abh&auml;ngt. Aufgrund dieser Bedeutung
            wechselseitiger Information wird KW der Auftraggeber*in laufend
            &uuml;ber die Entwicklung der Personalsuche berichten.
            <br />
            <br /> <strong style={{ fontWeight: 600 }}>III. Vertragsabschluss</strong>
            <br />
            <br /> Der Vertrag zwischen KW und der Auftraggeber*in kommt
            entweder schriftlich durch Unterzeichnung des Angebotes/der
            Auftragsbest&auml;tigung oder durch m&uuml;ndliche Auftragserteilung
            und entsprechende Annahme durch KW zustande. Die Annahme erfolgt
            dadurch, dass KW mit Erbringung der vereinbarten Dienstleistungen
            beginnt. <br />
            <br />
            <strong style={{ fontWeight: 600 }}>IV. Honorar</strong> <br />
            <br />
            1. Das Honorar richtet sich nach Art und Umfang der von KW
            auftragsgem&auml;ß zu erbringenden Leistungen und wird im Falle
            schriftlichen Vertragsabschlusses in der Auftragsbest&auml;tigung
            festgehalten (oder in anderer Form, wie E-Mail oder &auml;hnlichem).
            <br />
            <br /> 2. Das Honorar deckt den Arbeitsaufwand von KW f&uuml;r die
            Beratung der Auftraggeber*in, die Suche und Auswahl sowie die
            Pr&auml;sentation geeigneter Kandidat*innen ab und wird mit dem
            Zustandekommen eines Vertrages zwischen Auftraggeber*in und einem
            der Kandidat*innen f&auml;llig, soweit nicht etwas anderes
            vereinbart wird. Der Vertrag zwischen Auftraggeber*in und der
            Kandidat*in gilt mit Einigung &uuml;ber den Aufgabenbereich,
            Gehalt/Verdienst und Beginn des Vertragsverh&auml;ltnisses als
            zustande gekommen. Der schriftliche Vertragsabschluss oder der
            tats&auml;chliche Vertrags- bzw. Besch&auml;ftigungsbeginn sind
            f&uuml;r die F&auml;lligkeit des KW-Honorars nicht entscheidend.
            Sofern nichts Gegenteiliges vereinbart wurde, ermittelt sich das
            vereinbarte Honorar auf Basis des Jahresbrutto der Kandidat*in, d.h.
            inklusive aller variablen Gehaltsanteile (Boni, feste oder
            leistungsabh&auml;ngige Provisionen usw.) und sonstiger
            Sachbez&uuml;ge. Das Honorar wird zuz&uuml;glich der gesetzlichen
            USt verrechnet und ist von der Auftraggeber*in prompt nach
            Rechnungserhalt zu bezahlen. Erf&uuml;llungsort f&uuml;r die Zahlung
            ist der Sitz von KW in Wien.
            <br />
            <br /> 3. KW hat auch dann einen Anspruch auf das Honorar, wenn die
            betreffende Kandidat*in von der Auftraggeber*in genannt wurde oder
            bereits bekannt war, sowie f&uuml;r den Fall, dass sich die
            Auftraggeber*in und die jeweilige Kandidat*in vor Beginn des
            Vertragsverh&auml;ltnisses vom Vertrag l&ouml;sen.
            <br />
            <br /> 4. Wird eine Kandidat*in von der Auftraggeber*in
            zun&auml;chst abgelehnt oder entscheidet sich eine Kandidat*in
            zun&auml;chst gegen den Vertragsabschluss mit der Auftraggeber*in,
            kommt aber innerhalb von 12 Monaten nach der Pr&auml;sentation durch
            KW dennoch ein Vertrag zwischen Auftraggeber*in und Kandidat*in
            zustande, so wird das Honorar mit diesem Vertragsabschluss
            f&auml;llig.
            <br />
            <br /> 5. Das Honorar steht KW auch dann zu, wenn ein Vertrag
            zwischen einer Kandidat*in und einem mit der Auftraggeber*in
            rechtlich oder wirtschaftlich verbundenen Unternehmen geschlossen
            wird. S&auml;mtliche sonstigen Bedingungen f&uuml;r das Entstehen
            des Honoraranspruches gelten sinngem&auml;ß auch f&uuml;r diese
            Unternehmen.
            <br />
            <br /> 6. Allf&auml;llige Nebenkosten der Kandidat*innen
            (Reisekosten, Di&auml;ten etc.) werden der Auftraggeber*in im Fall
            vorheriger Vereinbarung gesondert in Rechnung gestellt.
            <br />
            <br /> 7. Die Kosten f&uuml;r auf ausdr&uuml;cklichen Wunsch der
            Auftraggeber*in geschaltete Inserate und sonstige Kosten/Spesen
            f&uuml;r von der Auftraggeber*in gew&uuml;nschte Sonderleistungen
            sind nicht vom Honorar umfasst. Inseratkosten und Honorare f&uuml;r
            Sonderleistungen sind unabh&auml;ngig vom Verlauf des
            Recruiting-Prozesses mit Rechnungserhalt prompt zur Zahlung
            f&auml;llig. <br />
            <br />
            8. Im Verzugsfall werden der Auftraggeber*in Verzugszinsen in
            H&ouml;he von 10% p.a. verrechnet. Zudem beh&auml;lt sich KW im
            Verzugsfall das Recht vor, allf&auml;llig gew&auml;hrte
            Nachl&auml;sse nach zu verrechnen.
            <br />
            <br /> <strong style={{ fontWeight: 600 }}>V. Inserat-Schaltungen/GlBG</strong>
            <br />
            <br /> 1. Der Auftraggeber*in ist die Bestimmung des § 9 Abs 2
            Gleichbehandlungsgesetz (GlBG) bekannt, wonach in Stellenanzeigen
            das jeweils gesetzlich/kollektivvertraglich festgelegte
            Mindestentgelt anzugeben und auf eine allf&auml;llige Bereitschaft
            zur &uuml;berzahlung hinzuweisen ist. <br />
            <br />
            2. Insbesondere vor diesem Hintergrund garantiert und haftet die
            Auftraggeber*in f&uuml;r die Richtigkeit und Vollst&auml;ndigkeit
            aller Informationen, die KW zwecks Erf&uuml;llung des Auftrags
            &uuml;bermittelt werden. Soweit entgeltbezogene Angaben durch die
            Auftraggeber*in ohne weitere Hinweise unterbleiben, darf KW davon
            ausgehen, dass § 9 Abs 2 GlBG nicht anwendbar ist. KW trifft
            diesbez&uuml;glich keine Verpflichtung zu n&auml;herer inhaltlicher
            Pr&uuml;fung.
            <br />
            <br /> 3. Sollte KW bzw. deren Mitarbeiter*innen durch Dritte oder
            eine Verwaltungsbeh&ouml;rde wegen (der Beteiligung an) einer
            Rechtsverletzung durch insofern unzureichende Angaben oder sonstige
            auf mangelhaften Informationen des Auftraggebers beruhende
            Insertionsfehler in Anspruch genommen werden, hat der Auftraggeber
            KW im Innenverh&auml;ltnis vollumf&auml;nglich schad- und klaglos zu
            halten. <br />
            <br />
            <strong style={{ fontWeight: 600 }}>VI. Anzeigepflicht</strong>
            <br />
            <br /> 1. Die Auftraggeber*in verpflichtet sich KW unverz&uuml;glich
            mitzuteilen, sobald man sich f&uuml;r eine Kandidat*in entschieden
            hat. <br />
            <br />
            2. Zudem wird die Auftraggeber*in den Abschluss eines Vertrages mit
            einer von KW namhaft gemachten Kandidat*in bekannt geben. VII.
            Garantie f&uuml;r Nachsuche Sofern Auftraggeber*in oder Kandidat*in
            innerhalb der im KW-Angebot festgelegten Garantiefrist den
            vermittelten Vertrag, aus welchem Grund auch immer, aufl&ouml;sen
            und KW &uuml;ber diesen Umstand von der Auftraggeber*in innerhalb
            von 3 Wochen schriftlich informiert wird, verpflichtet sich KW zur
            kostenlosen (allerdings unter Berechnung der anfallenden Auslagen)
            und einmaligen Nachsuche einer weiteren Kandidat*in f&uuml;r die
            identische Position. Dies auf Basis der f&uuml;r die
            urspr&uuml;ngliche Kandidat*innensuche relevanten
            Stellenbeschreibung sowie des Anforderungsprofils. <br />
            <br />
            <strong style={{ fontWeight: 600 }}>VII. Gew&auml;hrleistung</strong>
            <br />
            <br /> KW gew&auml;hrleistet sach- und fachgerechte Beratung der
            Auftraggeber*in und ein lege artis Vorgehen bei der
            Kandidat*innensuche und –auswahl. Sie steht nicht daf&uuml;r ein,
            dass eine von ihr nach sach- und fachgerechtem Vorgehen
            ausgew&auml;hlter und der Auftraggeber*in pr&auml;sentierte
            Kandidat*in alle gesetzten Erwartungen erf&uuml;llt oder bestimmte
            Ergebnisse erzielen kann. <br />
            <br />
            <strong style={{ fontWeight: 600 }}>VIII. Vertraulichkeit und Datenschutz</strong> <br />
            <br />
            1. Die Auftraggeber*in verpflichtet sich, s&auml;mtliche Namen der
            Kandidat*innen sowie alle &uuml;ber diese ihr zugegangenen
            Informationen streng vertraulich zu behandeln. Insbesondere
            verpflichtet sie sich, diese unter keinen Umst&auml;nden an dritte
            Personen oder Unternehmungen weiterzugeben oder sie auch nur namhaft
            zu machen.
            <br />
            <br />
            2. Auftraggeber*in und Kandidat*innen willigen ein, dass ihre durch
            die Gesch&auml;ftsbeziehung bekannt gewordenen Daten
            innerbetrieblich von KW gespeichert und automatisiert verarbeitet
            werden. Sie stimmen insbesondere auch der Weitergabe der Daten zur
            Anbahnung von Besch&auml;ftigungs- oder damit in Zusammenhang
            stehenden Vertr&auml;gen zu. X. Gerichtsstand Als ausschließlicher
            Gerichtsstand f&uuml;r alle Streitigkeiten aus oder im Zusammenhang
            mit Personalberatungsvertr&auml;gen wird Wien vereinbart. Es ist
            ausschließlich &ouml;sterreichisches Recht und Ausschluss der
            Verweisungsnormen anzuwenden.
          </p>
          <div className="footerUnternehmen" id="footerDesktop">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>

            <div className="right">
              <MaxerlRed width="150px" className="redLogoUnternehmen" />
              <MaxerlRed
                width="150px"
                className="redLogoUnternehmenFixed"
                style={{ marginRight: -65 }}
              />
              <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
              <p>
                Mag.a Karin Weiss
                <br /> +43 650 49 789 64
                <br />{" "}
                <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
                <br />
                <br />
                <NavHashLink to="/impressum#root">Impressum</NavHashLink>
                <NavHashLink to="/agbs#root">AGBs</NavHashLink>
                <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
              </p>
            </div>
          </div>
          <div className="footerUnternehmen" id="footerMobile">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="right" id="mobileRight">
        <MaxerlRed width="150px" className="redLogoUnternehmen" />
        <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
        <p>
          Mag.a Karin Weiss
          <br /> +43 650 49 789 64
          <br />{" "}
          <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
          <br />
          <br />
          <NavHashLink to="/impressum#root">Impressum</NavHashLink>
          <NavHashLink to="/agbs#root">AGBs</NavHashLink>
          <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
        </p>
      </div>
      <div className="whiteUnder"></div>
    </div>
  );
}
