import React, { useEffect } from "react";
import "./index.css";
import KandidatLogo from "../KandidatLogo";
import { FaLinkedinIn, FaXing } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { MDBBtn } from "mdbreact";
import MaxerlRed from "../MaxerlRed";
import MaxerlWhite from "../MaxerlWhite";
import { NavHashLink } from "react-router-hash-link";
import MobileUnternehmen from "../MobileUnternehmen";

export default function UnternehmenScreen(props) {
  useEffect(() => {
    const currentPage = props.location.pathname;

    props.trackPage(currentPage);
  }, [props]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
//      console.log(window.scrollY);
      let minus = 1050;
      if (document.body.clientHeight > 3000) {
        minus = 1350;
      }
      if (window.scrollY > document.body.clientHeight - minus) {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "180px";
          redLogo.style.marginRight = "-65px";
        }
      } else {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "90px";
        }
      }
    });
  }, []);

  return (
    <div id="all">
      <div className="allContainer">
        <div className="mainContainerUnt">
          <div className="redContainer">
            <div className="imgBox"></div>
            <div className="overImgText">
              <p>
                Gewinnen Sie
                <br />
                Zeit und Energie,
                <br /> indem Sie mir Ihre
                <br /> Personalsuche
                <br /> &uuml;berlassen.
              </p>
            </div>
            <div className="text">
              <p>
                Wer kennt das nicht? Sie wollen rasch und nachhaltig eine Stelle
                besetzen, schalten ein Stellenangebot und erhalten kaum bis
                keine Bewerbungen.{" "}
                <strong style={{ fontWeight: 600 }}>
                  Speziell IT-Spezialist*innen erreicht man am besten durch
                  Direktansprache – doch dafür finden Sie durch den enormen
                  Mehraufwand in Ihrem Tagesgesch&auml;ft nicht die notwendige
                  Zeit.
                </strong>
              </p>
            </div>
            <div className="underText">
              <strong style={{ fontWeight: 600 }}>
                &Uuml;berlassen Sie mir Ihre Personalsuche.
                <br />
                Das k&ouml;nnen Sie von mir erwarten:
                <br />
              </strong>

              <ul>
                <li>professionelle Unterstützung durch beste Vernetzung</li>
                <li>Kreativit&auml;t im Active Sourcing</li>
                <li>qualifizierte Kandidat*innen dank Vorauswahl durch</li>
                <li>persönliche Interviews</li>
                <li>ein Gespür für Teams, Menschen und Talente</li>
              </ul>
              <div className="friendlyContainer" id="friendlyDesktop">
                <p>
                  <strong style={{ fontWeight: 600 }}>
                    Ich freue mich darauf, Sie kennen zu lernen.
                  </strong>{" "}
                  Rufen sich mich an oder schreiben Sie mir ein Email unter:
                  <br />
                  <br />
                  +43 650 49 789 64 <br />
                  <a href="mailto:office@karin-weiss.at" className="mailHover">
                    office@karin-weiss.at
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="friendlyContainer" id="friendlyMobile">
            <p>
              <strong style={{ fontWeight: 600 }}>
                Ich freue mich darauf, Sie bei Ihren Anliegen unterst&uuml;tzen
                zu k&ouml;nnen.
              </strong>
              <br /> Rufen sich mich an oder schreiben Sie mir ein Email unter:
              <br />
              <br />
              +43 650 49 789 64 <br />
              <a href="mailto:office@karin-weiss.at" className="mailHoverWhite">
                office@karin-weiss.at
              </a>
            </p>
          </div>
          <div className="redContainer2">
            <div className="coaching" id="coachingDesktop">
              <h1 className="coach" id="coaching">
                Coaching
              </h1>
              <div className="coachText">
                <p>
                  <strong style={{ fontWeight: 600 }}>
                    Gerne unterstütze ich Sie mit meiner Expertise und Erfahrung
                    bei HR-Themen. Speziell für kleinere Unternehmen ohne
                    Personalabteilung:{" "}
                  </strong>
                  <br />
                  <ul className="coachUl">
                    <li>Erstellung eines Anforderungsprofils</li>
                    <li>On-boarding-Prozess</li>
                    <li>Mitarbeiter*innen-Gespräche</li>
                    <li>
                      Förderung potenzieller Führungskräfte innerhalb Ihres
                      Unternehmens
                    </li>
                  </ul>
                  <br />
                  Diese und weitere berufsbegleitenden Maßnahmen, kombiniert mit
                  meinem Blick von außen, unterst&uuml;tzen Sie bei einem{" "}
                  <strong style={{ fontWeight: 600 }}>
                    professionellen, wertsch&auml;tzenden Führungsstil.
                  </strong>
                </p>
              </div>
            </div>
            <MobileUnternehmen width="40%" id="mobileUnternehmen" />
            <h1 id="recruiting">Recruiting</h1>
            <div className="recruiting">
              <div className="recText">
                <p>
                  In einem{" "}
                  <strong style={{ fontWeight: 600 }}>
                    pers&ouml;nlichen Briefing
                  </strong>{" "}
                  erfahre ich von Ihnen{" "}
                  <strong style={{ fontWeight: 600 }}>
                    Ihre Anforderungen an Ihre neue Mitarbeiter*in.
                  </strong>{" "}
                  Neben fachlichen Informationen zählen für mich speziell auch{" "}
                  <strong style={{ fontWeight: 600 }}>
                    Einblicke in Ihre Unternehmenskultur und die
                    Zusammenstellung des bestehenden Teams,
                  </strong>{" "}
                  in das sich Ihre Wunschkandidat*in eingliedern wird.
                  <br />
                  <br /> Nach intensiver Recherche und pers&ouml;nlichen
                  Interviews treffe ich eine gezielte Vorauswahl und präsentiere
                  Ihnen anschließend Ihre Wunschkandidat*innen.
                  <br />
                  <br /> Ob Fach- oder F&uuml;hrungskräfte – Sie k&ouml;nnen mir
                  vertrauen. <br />
                  <br />
                  <strong style={{ fontWeight: 600 }}>
                    Mein Ziel sind begeisterte Kund*innen,
                  </strong>{" "}
                  das sind Sie als Unternehmen, wie auch die Kandidat*innen.
                </p>
              </div>

              <div className="recIcon">
                <KandidatLogo width="130px" style={{ marginTop: "-30px" }} />
              </div>
            </div>
            <div className="imgBox2"></div>
            <div className="coaching" id="coachingMobile">
              <h1 className="coach" id="coaching">
                Coaching
              </h1>
              <div className="coachText">
                <p>
                  Gerne unterst&uuml;tze ich Sie mit meiner Expertise und
                  Erfahrung bei HR-Themen. Speziell für kleinere Unternehmen
                  ohne Personalabteilung:
                  <br />
                  <ul className="coachUl">
                    <li>Erstellung eines Anforderungsprofils</li>
                    <li>On-boarding-Prozess</li>
                    <li>Mitarbeiter*innen-Gespräche</li>
                    <li>
                      Förderung potenzieller Führungskräfte innerhalb Ihres
                      Unternehmens
                    </li>
                  </ul>
                  <br />
                  Diese und weitere berufsbegleitenden Maßnahmen, kombiniert mit
                  meinem Blick von außen, unterst&uuml;tzen Sie bei einem
                  professionellen, wertsch&auml;tzenden Führungsstil.
                </p>
              </div>
            </div>
          </div>
          <div className="footerUnternehmen" id="footerDesktop">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>

            <div className="right">
              <MaxerlRed width="150px" className="redLogoUnternehmen" />
              <MaxerlRed
                width="150px"
                className="redLogoUnternehmenFixed"
                style={{ marginRight: -65 }}
              />
              <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
              <p>
                Mag.a Karin Weiss
                <br /> +43 650 49 789 64
                <br />{" "}
                <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
                <br />
                <br />
                <NavHashLink to="/impressum#root">Impressum</NavHashLink>
                <NavHashLink to="/agbs#root">AGBs</NavHashLink>
                <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
              </p>
            </div>
          </div>
          <div className="footerUnternehmen" id="footerMobile">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="right" id="mobileRight">
        <MaxerlRed width="150px" className="redLogoUnternehmen" />
        <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
        <p>
          Mag.a Karin Weiss
          <br /> +43 650 49 789 64
          <br />{" "}
          <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
          <br />
          <br />
          <NavHashLink to="/impressum#root">Impressum</NavHashLink>
          <NavHashLink to="/agbs#root">AGBs</NavHashLink>
          <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
        </p>
      </div>
      <div className="whiteUnder"></div>
    </div>
  );
}
