import React from "react";
import "mdbreact/dist/css/mdb.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import HomeScreen from "./Components/Home/HomeScreen";
import Navigator from "./Components/Navigator/Navigator";
import { Switch, Route, HashRouter } from "react-router-dom";
import UnternehmenScreen from "./Components/Unternehmen/UnternehmenScreen";
import KandidatenScreen from "./Components/Kandidatinnen/KandidatenScreen";
import KontaktScreen from "./Components/Kontakt/KontaktScreen";
import KarinWeissScreen from "./Components/KarinWeiss/karinweissScreen";
import NavigatorMobile from "./Components/NavMobile/NavigatorMobile";
import Datenschutz from "./Components/Rechtliches/Datenschutz";
import Impressum from "./Components/Rechtliches/Impressum";
import AGB from "./Components/Rechtliches/AGB";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import StellenangeboteScreen from "./Components/Stellenangebote/StellenangeboteScreen";

ReactGA.initialize("UA-154864702-2");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const trackPage = (page) => {
    ReactGA.set({
      page,
    });
    ReactGA.pageview(page);
  };

  return (
    <HashRouter basename={"/home"} hashType={"slash"}>
      <div className="topAllContainer">
        <div className="topMainContainer">
          <NavigatorMobile />
          <Navigator />
        </div>
      </div>

      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <HomeScreen {...props} trackPage={trackPage} />}
        />
        <Route
          exact
          path="/unternehmen"
          render={(props) => (
            <UnternehmenScreen {...props} trackPage={trackPage} />
          )}
        />
        <Route
          exact
          path="/kandidatInnen"
          render={(props) => (
            <KandidatenScreen {...props} trackPage={trackPage} />
          )}
        />
        <Route
          exact
          path="/karinweiss"
          render={(props) => (
            <KarinWeissScreen {...props} trackPage={trackPage} />
          )}
        />
        <Route
          exact
          path="/kontakt"
          render={(props) => <KontaktScreen {...props} trackPage={trackPage} />}
        />
        <Route
          exact
          path="/datenschutz"
          render={(props) => <Datenschutz {...props} trackPage={trackPage} />}
        />
        <Route
          exact
          path="/impressum"
          render={(props) => <Impressum {...props} trackPage={trackPage} />}
        />
        <Route
          exact
          path="/agbs"
          render={(props) => <AGB {...props} trackPage={trackPage} />}
        />
        <Route
          exact
          path="/stellenangebote"
          render={(props) => <StellenangeboteScreen {...props} trackPage={trackPage} />}
        />
      </Switch>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Akzeptieren"
        declineButtonText="Nicht Akzeptieren"
        declineButtonStyle={{
          backgroundColor: "rgba(210,210,210,1)",
          fontSize: "20px",
          color: "black",
          marginLeft: "auto",
        }}
        cookieName="Karin-Weiss-Cookie"
        style={{
          background: "white",
          color: "black",
          fontFamily: "bebas-neue-pro-semiexpanded, sans-serif",
          fontWeight: 300,
          zIndex: 999999,
        }}
        buttonStyle={{
          backgroundColor: "#d80d41",
          fontSize: "20px",
          color: "white",
          marginRight: "auto",
        }}
        expires={150}
      >
        Wir verwenden Cookies. Funktionale Cookies speichern technische
        Informationen, während Performance-Cookies die Browsing-Daten verfolgen,
        um uns bei der Optimierung unserer Website zu helfen. Wir verwenden auch
        Drittanbieter-Cookies von unseren Partnern. Diese werden in der
        Datenschutzerklärung aufgeführt.
      </CookieConsent>
    </HashRouter>
  );
}

export default App;
