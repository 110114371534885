import * as React from "react";

function KandidatLogo(props) {
  return (
    <svg id="kandlogo" data-name="Ebene 60" viewBox="0 0 103 318" {...props}>
      <defs>
        <clipPath id="prefix__clip-path-60">
          <path fill="none" d="M8.17 10.41h83.66V303.6H8.17z" />
        </clipPath>
        <style>
          {
            ".prefix__cls-60,.prefix__cls-80{fill:none;stroke:#fff}.prefix__cls-60{stroke-linecap:round}.prefix__cls-70{clip-path:url(#prefix__clip-path-60)}"
          }
        </style>
      </defs>
      <path
        className="prefix__cls-60"
        d="M32.13 58.14l7.8 1.38M31.9 58.16l-7.62 1.34"
      />
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        <path
          style={{ strokeWidth: "1px" }}
          className="prefix__cls-80"
          d="M35.91 26.62a4 4 0 10-4-4 4 4 0 004 4z"
        />
        <path
          className="prefix__cls-60"
          d="M31.79 26.2a9.36 9.36 0 00-2.27 4.5c-.56 2.8-1.7 7.51-1.7 10.24s2.27 4.57 5 4.94c1.23.16 4.15.39 5.78.51s1.48.93 1.4 1.61-1.57 7.87-1.72 9.35a2.12 2.12 0 002.19 2.19 2.19 2.19 0 002.08-1.5c.07-.22 2.22-11.18 2.22-11.18a3.39 3.39 0 00-3-3.88l-7.86-1.69"
        />
        <path
          className="prefix__cls-60"
          d="M24.73 26s.08 11.19.08 15.29 3.13 6.88 7.55 7.43c2.69.33 4.86.51 4.86.51"
        />
      </g>
      <path
        className="prefix__cls-80"
        d="M24.73 41.32V26.03"
        style={{ strokeWidth: "1px" }}
      />
      <path className="prefix__cls-60" d="M31.9 48.77v9.12" />
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        >
        <path
          className="prefix__cls-60"
          d="M45.07 39.28a1.76 1.76 0 001.73-1.79 1.79 1.79 0 00-1.75-1.81h-6.49M36.75 28s.06 4.93.06 5.92a1.77 1.77 0 001.74 1.79"
        />
      </g>
      <path className="prefix__cls-60" d="M45.04 39.27h-6.99" />
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        >
        <path
          className="prefix__cls-80"
          style={{ strokeWidth: "1px" }}
          d="M72.62 26.62a4 4 0 114-4 4 4 0 01-4 4z"
        />
        <path
          className="prefix__cls-60"
          d="M76.73 26.2A9.42 9.42 0 0179 30.7c.55 2.8 1.7 7.51 1.7 10.24s-2.27 4.57-5 4.94c-1.22.16-4.14.39-5.77.51s-1.46.93-1.34 1.64 1.57 7.87 1.72 9.35a2.13 2.13 0 01-2.19 2.19 2.19 2.19 0 01-2.12-1.5c0-.22-2.2-11.18-2.2-11.18a3.39 3.39 0 013-3.88l7.85-1.69"
        />
        <path
          className="prefix__cls-60"
          d="M83.79 26s-.07 11.19-.07 15.29-3.14 6.88-7.55 7.43c-2.7.33-4.86.51-4.86.51"
        />
      </g>
      <path
        className="prefix__cls-80"
        style={{ strokeWidth: "1px" }}
        d="M83.79 41.32V26.03"
      />
      <path
        className="prefix__cls-60"
        d="M76.63 48.77v9.12M76.4 58.14l-7.8 1.38M76.63 58.16l7.62 1.34"
      />
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        >
        <path
          className="prefix__cls-60"
          d="M63.46 39.28a1.76 1.76 0 01-1.73-1.79 1.79 1.79 0 011.74-1.81h6.5M71.77 28s-.06 4.93-.06 5.92A1.76 1.76 0 0170 35.67"
        />
      </g>
      <path
        className="prefix__cls-60"
        d="M63.49 39.27h6.99M44.33 39.26h19.84M67.47 16.27l-2.8-3.73M65.99 18.96h-4.23"
      />
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        >
        <path className="prefix__cls-60" d="M70.65 15.46l.52-4.55" />
      </g>
      <path className="prefix__cls-60" d="M54.01 113.81V39.78" />
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        >
        <path
          className="prefix__cls-60"
          d="M54 163.16a20.48 20.48 0 10-20.49-20.48A20.48 20.48 0 0054 163.16z"
        />
      </g>
      <path className="prefix__cls-60" d="M68.36 157.39l6.65 6.28" />
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        >
        <path
          className="prefix__cls-60"
          d="M86.12 178.67a2.81 2.81 0 003.94-4s-9.82-10.07-10.92-11.15a2.81 2.81 0 10-3.94 4c1.1 1.08 10.92 11.15 10.92 11.15zM54.48 126.58s-.67-1.88-1.35 0l-1.47 4.1a3 3 0 01-2.66 1.94l-4.49.11s-2 .05-.4 1.25l3.54 2.67a3 3 0 011 3.12l-1.26 4.17s-.57 1.91 1.08.79l3.68-2.49a3.08 3.08 0 013.31 0l3.68 2.49s1.66 1.12 1.08-.79L59 139.77a3 3 0 011-3.12l4.34-3.27s.8-.6-.2-.63l-4.49-.11a4.55 4.55 0 01-4-2.9zM44.08 157.39s-.07-1.69-.07-3.7a6.46 6.46 0 016.46-6.46h7a6.46 6.46 0 016.53 6.46c0 2-.07 3.7-.07 3.7M32.53 182.12s-.06-1.29-.06-2.82a4.92 4.92 0 014.94-4.9h5.13a4.92 4.92 0 014.94 4.9v2.82M76.38 160v-2.82a4.91 4.91 0 014.93-4.9h5.09a4.91 4.91 0 014.93 4.9V160M75 132.43a4.94 4.94 0 012.67-.77h5.13a4.92 4.92 0 014.94 4.9c0 1.53-.06 2.82-.06 2.82M47.59 182.12s-.05-1.29-.05-2.82a4.91 4.91 0 014.93-4.9h5.14a4.91 4.91 0 014.93 4.9v2.82M68.54 174.39h-1a4.92 4.92 0 00-4.94 4.9c0 1.53.06 2.82.06 2.82M71.67 174.39h1a4.92 4.92 0 014.94 4.9c0 1.53-.06 2.82-.06 2.82"
        />
      </g>
      <path
        className="prefix__cls-60"
        d="M53.69 156.26v1.24M53.69 152.82v1.24M53.69 149.38v1.24"
      />
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        >
        <circle className="prefix__cls-60" cx={39.98} cy={167.87} r={4.36} />
        <circle className="prefix__cls-60" cx={83.83} cy={145.77} r={4.36} />
        <circle className="prefix__cls-60" cx={79.7} cy={125.34} r={4.36} />
        <path
          className="prefix__cls-60"
          d="M51.37 165.52a4.27 4.27 0 00-.69 2.35 4.36 4.36 0 108-2.44M68.73 163.73a4.36 4.36 0 105 6.52"
        />
        <circle className="prefix__cls-60" cx={19.29} cy={255.27} r={4.36} />
        <circle className="prefix__cls-60" cx={44.75} cy={254.97} r={4.36} />
        <path
          className="prefix__cls-60"
          d="M19.52 263.5s2.11 5.21 2.54 5.93a9.07 9.07 0 001.93 2.1c.32.24 5.13 3.2 6.15 3.81a2.15 2.15 0 002.94-.73 2.24 2.24 0 00-.73-2.95s-2.73-1.38-2.83-1.38-2.63-1.34-3.38-1.79a3.55 3.55 0 01-1.4-1.59c-.7-1.47-1.39-4.09-1.91-5a4.91 4.91 0 00-4-2.08 5.17 5.17 0 00-5 5.08c-.08 1.36-.23 6.86-.37 13.17M40.52 270.8s-.63 28.43-.63 29.61a2.15 2.15 0 004.3 0l.69-20.16s1.53 19 1.53 20.16a2.15 2.15 0 104.29 0s-.61-32.46-.84-36a5.16 5.16 0 00-5-5.09 4.91 4.91 0 00-4 2.08c-.53.9-1.21 3.52-1.91 5a3.61 3.61 0 01-1.4 1.59c-.76.45-3.39 1.8-3.39 1.8"
        />
        <path
          className="prefix__cls-60"
          d="M44.88 263.5s-3.19 5.66-3.54 6.13a10.44 10.44 0 01-2.1 1.75c-.58.35-6.51 3.73-6.51 3.73M8.67 281h27.48a.81.81 0 00.82-.83v-1.25a.8.8 0 00-.82-.82H13.47M26.82 280.92l5.39 21.48M50.65 286.69l4.67-.05a1.36 1.36 0 001.36-1.23s1.61-12.3 1.79-13.4a2.78 2.78 0 01.57-1.32 3 3 0 012.27-1.14s6.24.15 6.81.19a1.78 1.78 0 011.5.86 1.55 1.55 0 01.15.93c-.11.76-1.82 14.83-1.82 14.83-.21 1.23-1 2.47-2.11 2.53-.93.05-15 .11-15 .11"
        />
      </g>
      <path
        className="prefix__cls-60"
        d="M54.06 286.67h7.59M59.14 291.63v9.44M55.65 301.07h7.12"
      />
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        >
        <path
          className="prefix__cls-60"
          d="M54.1 302.33a1.27 1.27 0 10-1.26-1.26 1.27 1.27 0 001.26 1.26zM64.18 302.33a1.27 1.27 0 10-1.26-1.26 1.26 1.26 0 001.26 1.26z"
        />
      </g>
      <g className="prefix__cls-70" style={{ strokeWidth: "1px" }}>
        {" "}
        >
        <path
          className="prefix__cls-60"
          d="M13.33 283.54C13.15 292.37 13 301 13 301a2.15 2.15 0 004.29 0c0-1.19 1.48-18 1.48-18l.74 18a2.15 2.15 0 004.29 0c0-.64-.18-9.37-.34-17.27M13.47 278.05H8.9M23.33 278.08c-.09-4.18-.15-7.25-.15-7.25"
        />
      </g>
      <path
        className="prefix__cls-60"
        d="M44.88 280.25v.88M18.75 283.1v.47M32.47 181.99v61.15"
      />
    </svg>
  );
}

export default KandidatLogo;
