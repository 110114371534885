import React, { useEffect } from "react";
import "./index.css";
import MaxerlRed from "../MaxerlRed";
import MaxerlWhite from "../MaxerlWhite";
import { MDBBtn } from "mdbreact";
import { FaLinkedinIn, FaXing } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { NavHashLink } from "react-router-hash-link";

export default function Datenschutz(props) {
 
  useEffect(() => {
    const currentPage = props.location.pathname;

    props.trackPage(currentPage);
  }, [props]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
//      console.log(window.scrollY);
      let minus = 1040;
      if (document.body.clientHeight > 22680) {
        minus = 1320;
      }
      if (window.scrollY > document.body.clientHeight - minus) {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "180px";
          redLogo.style.marginRight = "-65px";
        }
      } else {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];
          const redLogoFixed = document.getElementsByClassName(
            "redLogoUnternehmenFixed"
          )[0];
          redLogoFixed.style.display = "none";

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "90px";
        }
      }
    });
  }, []);

  return (
    <div id="all">
      <div className="allContainer">
        <div className="mainContainerUnt">
          <p className="datenText">
            <h3>Datenschutzerkl&auml;rung</h3>
            <p>
              <h3>Einleitung</h3>
            </p>
            Mit der folgenden Datenschutzerkl&auml;rung m&ouml;chten wir Sie
            dar&uuml;ber aufkl&auml;ren, welche Arten Ihrer personenbezogenen
            Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen
            Zwecken und in welchem Umfang verarbeiten. Die
            Datenschutzerkl&auml;rung gilt f&uuml;r alle von uns
            durchgef&uuml;hrten Verarbeitungen personenbezogener Daten, sowohl
            im Rahmen der Erbringung unserer Leistungen als auch insbesondere
            auf unseren Webseiten, in mobilen Applikationen sowie innerhalb
            externer Onlinepr&auml;senzen, wie z.B. unserer Social-Media-Profile
            (nachfolgend zusammenfassend bezeichnet als "Onlineangebot“). Die
            verwendeten Begriffe sind nicht geschlechtsspezifisch. Stand: 2.
            Juli 2020
            <br />
            <br /> Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
            <br />
            <br />
            Stand: 2. Juli 2020
            <h3>Inhalts&uuml;bersicht</h3>
            <ul className="datenUl">
              <li>Einleitung</li>
              <li>Verantwortlicher</li>
              <li>&Uuml;bersicht der Verarbeitung</li>
              <li>Maßgebliche Rechtsgrundlagen</li>
              <li>Sicherheitsmaßnahmen</li>
              <li>Datenverarbeitung in Drittl&auml;ndern</li>
              <li>Einsatz von Cookies</li>
              <li>Bewerbungsverfahren</li>
              <li>Online Marketing</li>
              <li>Pr&auml;senzen in sozialen Netzwerken (Social Media)</li>
              <li>L&ouml;schung von Daten</li>
              <li>Rechte der betroffenen Personen</li>
            </ul>
            <h3>Verantwortlicher</h3>
            Mag.a Karin Weiß
            <br /> Marokkanergasse 3/1/59
            <br /> 1030 Wien
            <br />
            <strong style={{ fontWeight: 600 }}>E-Mail-Adresse:</strong>{" "}
            <a href="mailto:office@karin-weiss.at" className="mailHoverWhite">
              office@karin-weiss.at
            </a>
            <br /> <strong style={{ fontWeight: 600 }}>Telefon:</strong> +43 650
            49 789 64 <br />
            <strong style={{ fontWeight: 600 }}>Impressum:</strong>{" "}
            www.karin-weiss.at
            <br /> <h3>Übersicht der Verarbeitungen</h3>
            Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten
            und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
            betroffenen Personen. <br />
            <br />
            Arten der verarbeiteten Daten
            <li>Bestandsdaten (z.B. Namen, Adressen).</li>
            <li>
              Bewerberdaten (z.B. Angaben zur Person, Post- und Kontaktadressen,
              die zur Bewerbung geh&ouml;renden Unterlagen und die darin
              enthaltenen Informationen, wie z.B. Anschreiben, Lebenslauf,
              Zeugnisse sowie weitere im Hinblick auf eine konkrete Stelle oder
              freiwillig von Bewerbern mitgeteilte Informationen zu deren Person
              oder Qualifikation).{" "}
            </li>{" "}
            <li>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos). </li>
            <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
            <li>
              Meta-/Kommunikationsdaten (z.B. Ger&auml;te-Informationen,
              IP-Adressen).
            </li>
            <li>
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).
            </li>
            <h3>Kategorien betroffener Personen</h3>
            <ul className="datenUl">
              <li>Bewerber.</li>
              <li>Interessenten.</li>
              <li>Kommunikationspartner.</li>
              <li>
                Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
            </ul>
            <h3>Zwecke der Verarbeitung</h3>
            <ul className="datenUl">
              <li>Besuchsaktionsauswertung.</li>
              <li>
                Bewerbungsverfahren (Begr&uuml;ndung und etwaige sp&auml;tere
                Durchf&uuml;hrung sowie m&ouml;gliche sp&auml;tere Beendigung
                des Besch&auml;ftigungsverh&auml;ltnisses.).
              </li>
              <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
              <li>Interessenbasiertes und verhaltensbezogenes Marketing.</li>
              <li>Kontaktanfragen und Kommunikation.</li>
              <li>
                Konversionsmessung (Messung der Effektivit&auml;t von
                Marketingma&szlig;nahmen).
              </li>
              <li>Profiling (Erstellen von Nutzerprofilen).</li>
              <li>Remarketing.</li>
              <li>
                Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
                wiederkehrender Besucher).
              </li>
              <li>Sicherheitsma&szlig;nahmen.</li>
              <li>
                Tracking (z.B. interessens-/verhaltensbezogenes Profiling,
                Nutzung von Cookies).
              </li>
              <li>Vertragliche Leistungen und Service.</li>
            </ul>
            <h3>Ma&szlig;gebliche Rechtsgrundlagen</h3>
            <p>
              Im Folgenden teilen wir die Rechtsgrundlagen der
              Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
              personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass
              zus&auml;tzlich zu den Regelungen der DSGVO die nationalen
              Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland
              gelten k&ouml;nnen. Sollten ferner im Einzelfall speziellere
              Rechtsgrundlagen ma&szlig;geblich sein, teilen wir Ihnen diese in
              der Datenschutzerkl&auml;rung mit.
            </p>
            <p>&nbsp;</p>
            <ul className="datenUl">
              <li>
                <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong>{" "}
                - Die betroffene Person hat ihre Einwilligung in die
                Verarbeitung der sie betreffenden personenbezogenen Daten
                f&uuml;r einen spezifischen Zweck oder mehrere bestimmte Zwecke
                gegeben.
              </li>
              <li>
                <strong>
                  Vertragserf&uuml;llung und vorvertragliche Anfragen (Art. 6
                  Abs. 1 S. 1 lit. b. DSGVO)
                </strong>{" "}
                - Die Verarbeitung ist f&uuml;r die Erf&uuml;llung eines
                Vertrags, dessen Vertragspartei die betroffene Person ist, oder
                zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
                erforderlich, die auf Anfrage der betroffenen Person erfolgen.
              </li>
              <li>
                <strong>
                  Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)
                </strong>{" "}
                - Die Verarbeitung ist zur Wahrung der berechtigten Interessen
                des Verantwortlichen oder eines Dritten erforderlich, sofern
                nicht die Interessen oder Grundrechte und Grundfreiheiten der
                betroffenen Person, die den Schutz personenbezogener Daten
                erfordern, &uuml;berwiegen.
              </li>
              <li>
                <strong>
                  Art. 9 Abs. 1 S. 1 lit. b DSGVO (Bewerbungsverfahren als
                  vorvertragliches bzw. vertragliches Verh&auml;ltnis) (Soweit
                  im Rahmen des Bewerbungsverfahrens besondere Kategorien von
                  personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B.
                  Gesundheitsdaten, wie Schwerbehinderteneigenschaft oder
                  ethnische Herkunft) bei Bewerbern angefragt werden, damit der
                  Verantwortliche oder die betroffene Person die ihm bzw. ihr
                  aus dem Arbeitsrecht und dem Recht der sozialen Sicherheit und
                  des Sozialschutzes erwachsenden Rechte aus&uuml;ben und seinen
                  bzw. ihren diesbez&uuml;glichen Pflichten nachkommen kann,
                  erfolgt deren Verarbeitung nach Art. 9 Abs. 2 lit. b. DSGVO,
                  im Fall des Schutzes lebenswichtiger Interessen der Bewerber
                  oder anderer Personen gem. Art. 9 Abs. 2 lit. c. DSGVO oder
                  f&uuml;r Zwecke der Gesundheitsvorsorge oder der
                  Arbeitsmedizin, f&uuml;r die Beurteilung der
                  Arbeitsf&auml;higkeit des Besch&auml;ftigten, f&uuml;r die
                  medizinische Diagnostik, die Versorgung oder Behandlung im
                  Gesundheits- oder Sozialbereich oder f&uuml;r die Verwaltung
                  von Systemen und Diensten im Gesundheits- oder Sozialbereich
                  gem. Art. 9 Abs. 2 lit. h. DSGVO. Im Fall einer auf
                  freiwilliger Einwilligung beruhenden Mitteilung von besonderen
                  Kategorien von Daten, erfolgt deren Verarbeitung auf Grundlage
                  von Art. 9 Abs. 2 lit. a. DSGVO.)
                </strong>{" "}
                - .
              </li>
            </ul>
            <p>
              <strong>
                Nationale Datenschutzregelungen in &Ouml;sterreich
              </strong>
              : Zus&auml;tzlich zu den Datenschutzregelungen der
              Datenschutz-Grundverordnung gelten nationale Regelungen zum
              Datenschutz in &Ouml;sterreich. Hierzu geh&ouml;rt insbesondere
              das Bundesgesetz zum Schutz nat&uuml;rlicher Personen bei der
              Verarbeitung personenbezogener Daten (Datenschutzgesetz &ndash;
              DSG). Das Datenschutzgesetz enth&auml;lt insbesondere
              Spezialregelungen zum Recht auf Auskunft, zum Recht auf
              Richtigstellung oder L&ouml;schung, zur Verarbeitung besonderer
              Kategorien personenbezogener Daten, zur Verarbeitung f&uuml;r
              andere Zwecke und zur &Uuml;bermittlung sowie zur automatisierten
              Entscheidungsfindung im Einzelfall.
            </p>
            <p>&nbsp;</p>
            <h2>Sicherheitsma&szlig;nahmen</h2>
            <p>
              Wir treffen nach Ma&szlig;gabe der gesetzlichen Vorgaben unter
              Ber&uuml;cksichtigung des Stands der Technik, der
              Implementierungskosten und der Art, des Umfangs, der Umst&auml;nde
              und der Zwecke der Verarbeitung sowie der unterschiedlichen
              Eintrittswahrscheinlichkeiten und des Ausma&szlig;es der Bedrohung
              der Rechte und Freiheiten nat&uuml;rlicher Personen geeignete
              technische und organisatorische Ma&szlig;nahmen, um ein dem Risiko
              angemessenes Schutzniveau zu gew&auml;hrleisten.
            </p>
            <p>
              Zu den Ma&szlig;nahmen geh&ouml;ren insbesondere die Sicherung der
              Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten
              durch Kontrolle des physischen und elektronischen Zugangs zu den
              Daten als auch des sie betreffenden Zugriffs, der Eingabe, der
              Weitergabe, der Sicherung der Verf&uuml;gbarkeit und ihrer
              Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
              Wahrnehmung von Betroffenenrechten, die L&ouml;schung von Daten
              und Reaktionen auf die Gef&auml;hrdung der Daten
              gew&auml;hrleisten. Ferner ber&uuml;cksichtigen wir den Schutz
              personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl
              von Hardware, Software sowie Verfahren entsprechend dem Prinzip
              des Datenschutzes, durch Technikgestaltung und durch
              datenschutzfreundliche Voreinstellungen.
            </p>
            <p>
              <strong>K&uuml;rzung der IP-Adresse</strong>: Sofern es uns
              m&ouml;glich ist oder eine Speicherung der IP-Adresse nicht
              erforderlich ist, k&uuml;rzen wir oder lassen Ihre IP-Adresse
              k&uuml;rzen. Im Fall der K&uuml;rzung der IP-Adresse, auch als
              "IP-Masking" bezeichnet, wird das letzte Oktett, d.h., die letzten
              beiden Zahlen einer IP-Adresse, gel&ouml;scht (die IP-Adresse ist
              in diesem Kontext eine einem Internetanschluss durch den
              Online-Zugangs-Provider individuell zugeordnete Kennung). Mit der
              K&uuml;rzung der IP-Adresse soll die Identifizierung einer Person
              anhand ihrer IP-Adresse verhindert oder wesentlich erschwert
              werden.
            </p>
            <p>
              <strong>SSL-Verschl&uuml;sselung (https)</strong>: Um Ihre via
              unser Online-Angebot &uuml;bermittelten Daten zu sch&uuml;tzen,
              nutzen wir eine SSL-Verschl&uuml;sselung. Sie erkennen derart
              verschl&uuml;sselte Verbindungen an dem Pr&auml;fix https:// in
              der Adresszeile Ihres Browsers.
            </p>
            <h2>Datenverarbeitung in Drittl&auml;ndern</h2>
            <p>
              Sofern wir Daten in einem Drittland (d.h., au&szlig;erhalb der
              Europ&auml;ischen Union (EU), des Europ&auml;ischen
              Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im
              Rahmen der Inanspruchnahme von Diensten Dritter oder der
              Offenlegung bzw. &Uuml;bermittlung von Daten an andere Personen,
              Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang
              mit den gesetzlichen Vorgaben.
            </p>
            <p>
              Vorbehaltlich ausdr&uuml;cklicher Einwilligung oder vertraglich
              oder gesetzlich erforderlicher &Uuml;bermittlung verarbeiten oder
              lassen wir die Daten nur in Drittl&auml;ndern mit einem
              anerkannten Datenschutzniveau, zu denen die unter dem
              "Privacy-Shield" zertifizierten US-Verarbeiter geh&ouml;ren, oder
              auf Grundlage besonderer Garantien, wie z.B. vertraglicher
              Verpflichtung durch sogenannte Standardschutzklauseln der
              EU-Kommission, des Vorliegens von Zertifizierungen oder
              verbindlicher interner Datenschutzvorschriften, verarbeiten (Art.
              44 bis 49 DSGVO, Informationsseite der EU-Kommission:{" "}
              <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de">
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
              </a>{" "}
              ).
            </p>
            <h2>Einsatz von Cookies</h2>
            <p>
              Cookies sind Textdateien, die Daten von besuchten Websites oder
              Domains enthalten und von einem Browser auf dem Computer des
              Benutzers gespeichert werden. Ein Cookie dient in erster Linie
              dazu, die Informationen &uuml;ber einen Benutzer w&auml;hrend oder
              nach seinem Besuch innerhalb eines Onlineangebotes zu speichern.
              Zu den gespeicherten Angaben k&ouml;nnen z.B. die
              Spracheinstellungen auf einer Webseite, der Loginstatus, ein
              Warenkorb oder die Stelle, an der ein Video geschaut wurde,
              geh&ouml;ren. Zu dem Begriff der Cookies z&auml;hlen wir ferner
              andere Technologien, die die gleichen Funktionen wie Cookies
              erf&uuml;llen (z.B., wenn Angaben der Nutzer anhand pseudonymer
              Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs"
              bezeichnet)
            </p>
            <p>
              <strong>
                Die folgenden Cookie-Typen und Funktionen werden unterschieden:
              </strong>
            </p>
            <ul className="datenUl">
              <li>
                <strong>
                  Tempor&auml;re Cookies (auch: Session- oder Sitzungs-Cookies):
                </strong>
                &nbsp;Tempor&auml;re Cookies werden sp&auml;testens
                gel&ouml;scht, nachdem ein Nutzer ein Online-Angebot verlassen
                und seinen Browser geschlossen hat.
              </li>
              <li>
                <strong>Permanente Cookies:</strong>&nbsp;Permanente Cookies
                bleiben auch nach dem Schlie&szlig;en des Browsers gespeichert.
                So kann beispielsweise der Login-Status gespeichert oder
                bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine
                Website erneut besucht. Ebenso k&ouml;nnen die Interessen von
                Nutzern, die zur Reichweitenmessung oder zu Marketingzwecken
                verwendet werden, in einem solchen Cookie gespeichert werden.
              </li>
              <li>
                <strong>First-Party-Cookies:</strong>&nbsp;First-Party-Cookies
                werden von uns selbst gesetzt.
              </li>
              <li>
                <strong>
                  Third-Party-Cookies (auch: Drittanbieter-Cookies)
                </strong>
                : Drittanbieter-Cookies werden haupts&auml;chlich von
                Werbetreibenden (sog. Dritten) verwendet, um
                Benutzerinformationen zu verarbeiten.
              </li>
              <li>
                <strong>
                  Notwendige (auch: essentielle oder unbedingt erforderliche)
                  Cookies:
                </strong>{" "}
                Cookies k&ouml;nnen zum einen f&uuml;r den Betrieb einer
                Webseite unbedingt erforderlich sein (z.B. um Logins oder andere
                Nutzereingaben zu speichern oder aus Gr&uuml;nden der
                Sicherheit).
              </li>
              <li>
                <strong>
                  Statistik-, Marketing- und Personalisierungs-Cookies
                </strong>
                : Ferner werden Cookies im Regelfall auch im Rahmen der
                Reichweitenmessung eingesetzt sowie dann, wenn die Interessen
                eines Nutzers oder sein Verhalten (z.B. Betrachten bestimmter
                Inhalte, Nutzen von Funktionen etc.) auf einzelnen Webseiten in
                einem Nutzerprofil gespeichert werden. Solche Profile dienen
                dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren
                potentiellen Interessen entsprechen. Dieses Verfahren wird auch
                als "Tracking", d.h., Nachverfolgung der potentiellen Interessen
                der Nutzer bezeichnet. . Soweit wir Cookies oder
                "Tracking"-Technologien einsetzen, informieren wir Sie gesondert
                in unserer Datenschutzerkl&auml;rung oder im Rahmen der
                Einholung einer Einwilligung.
              </li>
            </ul>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen: </strong>&nbsp;Auf welcher
              Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von
              Cookies verarbeiten, h&auml;ngt davon ab, ob wir Sie um eine
              Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung
              von Cookies einwilligen, ist die Rechtsgrundlage der Verarbeitung
              Ihrer Daten die erkl&auml;rte Einwilligung. Andernfalls werden die
              mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer
              berechtigten Interessen (z.B. an einem betriebswirtschaftlichen
              Betrieb unseres Onlineangebotes und dessen Verbesserung)
              verarbeitet oder, wenn der Einsatz von Cookies erforderlich ist,
              um unsere vertraglichen Verpflichtungen zu erf&uuml;llen.
            </p>
            <p>
              <strong>Speicherdauer: </strong>Sofern wir Ihnen keine expliziten
              Angaben zur Speicherdauer von permanenten Cookies mitteilen (z. B.
              im Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus,
              dass die Speicherdauer bis zu zwei Jahre betragen kann.
            </p>
            <p>
              <strong>
                Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):{" "}
              </strong>
              &nbsp;Abh&auml;ngig davon, ob die Verarbeitung auf Grundlage einer
              Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie
              jederzeit die M&ouml;glichkeit, eine erteilte Einwilligung zu
              widerrufen oder der Verarbeitung Ihrer Daten durch
              Cookie-Technologien zu widersprechen (zusammenfassend als
              "Opt-Out" bezeichnet). Sie k&ouml;nnen Ihren Widerspruch
              zun&auml;chst mittels der Einstellungen Ihres Browsers
              erkl&auml;ren, z.B., indem Sie die Nutzung von Cookies
              deaktivieren (wobei hierdurch auch die Funktionsf&auml;higkeit
              unseres Onlineangebotes eingeschr&auml;nkt werden kann). Ein
              Widerspruch gegen den Einsatz von Cookies zu Zwecken des
              Onlinemarketings kann auch mittels einer Vielzahl von Diensten,
              vor allem im Fall des Trackings, &uuml;ber die Webseiten{" "}
              <a href="https://optout.aboutads.info">
                https://optout.aboutads.info
              </a>{" "}
              und{" "}
              <a href="https://www.youronlinechoices.com/">
                https://www.youronlinechoices.com/
              </a>{" "}
              erkl&auml;rt werden. Daneben k&ouml;nnen Sie weitere
              Widerspruchshinweise im Rahmen der Angaben zu den eingesetzten
              Dienstleistern und Cookies erhalten.
            </p>
            <ul className="datenUl">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten&nbsp;
                (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Ger&auml;te-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO).
              </li>
            </ul>
            <p>
              "Karin Weiss Recruiting Personal Coaching" ist ein
              Beratungsunternehmen,&nbsp; das Dienst- und Beratungsleistungen
              vor allem auf dem Gebiet der Personalsuche und -auswahl und
              Coaching erbringt.
            </p>
            <h2>Bewerbungsverfahren</h2>
            <p>
              Das Bewerbungsverfahren setzt voraus, dass Bewerber uns die
              f&uuml;r deren Beurteilung und Auswahl erforderlichen Daten
              mitteilen. Welche Informationen erforderlich sind, ergibt sich aus
              der Stellenbeschreibung oder im Fall von Onlineformularen aus den
              dortigen Angaben.
            </p>
            <p>
              Grunds&auml;tzlich geh&ouml;ren zu den erforderlichen Angaben, die
              Informationen zur Person, wie der Name, die Adresse, eine
              Kontaktm&ouml;glichkeit sowie die Nachweise &uuml;ber die f&uuml;r
              eine Stelle notwendigen Qualifikationen. Auf Anfragen teilen wir
              zus&auml;tzlich gerne mit, welche Angaben ben&ouml;tigt werden.
            </p>
            <p>
              Sofern zur Verf&uuml;gung gestellt, k&ouml;nnen uns Bewerber ihre
              Bewerbungen mittels eines Onlineformulars &uuml;bermitteln. Die
              Daten werden entsprechend dem Stand der Technik verschl&uuml;sselt
              an uns &uuml;bertragen. Ebenfalls k&ouml;nnen Bewerber uns ihre
              Bewerbungen via E-Mail &uuml;bermitteln. Hierbei bitten wir jedoch
              zu beachten, dass E-Mails im Internet grunds&auml;tzlich nicht
              verschl&uuml;sselt versendet werden. Im Regelfall werden E-Mails
              zwar auf dem Transportweg verschl&uuml;sselt, aber nicht auf den
              Servern von denen sie abgesendet und empfangen werden. Wir
              k&ouml;nnen daher f&uuml;r den &Uuml;bertragungsweg der Bewerbung
              zwischen dem Absender und dem Empfang auf unserem Server keine
              Verantwortung &uuml;bernehmen.
            </p>
            <p>
              F&uuml;r Zwecke der Bewerbersuche, Einreichung von Bewerbungen und
              Auswahl von Bewerbern k&ouml;nnen wir unter Beachtung der
              gesetzlichen Vorgaben, Bewerbermanagement-, bzw.
              Recruitment-Software und Plattformen und Leistungen von
              Drittanbietern in Anspruch nehmen.
            </p>
            <p>
              Bewerber k&ouml;nnen uns gerne zur Art der Einreichung der
              Bewerbung kontaktieren oder uns die Bewerbung auf dem Postweg
              zuzusenden.
            </p>
            <p>
              <strong>Verarbeitung besonderer Kategorien von Daten:</strong>{" "}
              Soweit im Rahmen des Bewerbungsverfahrens besondere Kategorien von
              personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B.
              Gesundheitsdaten, wie z.B. Schwerbehinderteneigenschaft oder
              ethnische Herkunft) bei Bewerbern angefragt werden, damit der
              Verantwortliche oder die betroffene Person die ihm bzw. ihr aus
              dem Arbeitsrecht und dem Recht der sozialen Sicherheit und des
              Sozialschutzes erwachsenden Rechte aus&uuml;ben und seinen bzw.
              ihren diesbez&uuml;glichen Pflichten nachkommen kann, erfolgt
              deren Verarbeitung nach Art. 9 Abs. 2 lit. b. DSGVO, im Fall des
              Schutzes lebenswichtiger Interessen der Bewerber oder anderer
              Personen gem. Art. 9 Abs. 2 lit. c. DSGVO oder f&uuml;r Zwecke der
              Gesundheitsvorsorge oder der Arbeitsmedizin, f&uuml;r die
              Beurteilung der Arbeitsf&auml;higkeit des Besch&auml;ftigten,
              f&uuml;r die medizinische Diagnostik, f&uuml;r die Versorgung oder
              Behandlung im Gesundheits- oder Sozialbereich oder f&uuml;r die
              Verwaltung von Systemen und Diensten im Gesundheits- oder
              Sozialbereich gem. Art. 9 Abs. 2 lit. h. DSGVO. Im Fall einer auf
              freiwilliger Einwilligung beruhenden Mitteilung der besonderen
              Kategorien von Daten erfolgt deren Verarbeitung auf Grundlage von
              Art. 9 Abs. 2 lit. a. DSGVO.
            </p>
            <p>
              <strong>L&ouml;schung von Daten:</strong> Die von den Bewerbern
              zur Verf&uuml;gung gestellten Daten k&ouml;nnen im Fall einer
              erfolgreichen Bewerbung f&uuml;r die Zwecke des
              Besch&auml;ftigungsverh&auml;ltnisses von uns weiterverarbeitet
              werden. Andernfalls, sofern die Bewerbung auf ein Stellenangebot
              nicht erfolgreich ist, werden die Daten der Bewerber
              gel&ouml;scht. Die Daten der Bewerber werden ebenfalls
              gel&ouml;scht, wenn eine Bewerbung zur&uuml;ckgezogen wird, wozu
              die Bewerber jederzeit berechtigt sind. Die L&ouml;schung erfolgt,
              vorbehaltlich eines berechtigten Widerrufs der Bewerber,
              sp&auml;testens nach dem Ablauf eines Zeitraums von sechs Monaten,
              damit wir etwaige Anschlussfragen zu der Bewerbung beantworten und
              unseren Nachweispflichten aus den Vorschriften zur
              Gleichbehandlung von Bewerbern nachkommen k&ouml;nnen. Rechnungen
              &uuml;ber etwaige Reisekostenerstattung werden entsprechend den
              steuerrechtlichen Vorgaben archiviert.
            </p>
            <p>
              <strong>Aufnahme in einen Bewerberpool:</strong> Die Aufnahme in
              einen Bewerber-Pool, sofern angeboten, erfolgt auf Grundlage einer
              Einwilligung. Die Bewerber werden dar&uuml;ber belehrt, dass ihre
              Zustimmung zur Aufnahme in den Talentpool freiwillig ist, keinen
              Einfluss auf das laufende Bewerbungsverfahren hat und sie ihre
              Einwilligung jederzeit f&uuml;r die Zukunft widerrufen
              k&ouml;nnen.
            </p>
            <p>
              <strong>
                Dauer der Aufbewahrung von Daten im Bewerber-Pool in Monaten:
              </strong>{" "}
              24
            </p>
            <ul className="datenUl">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bewerberdaten (z.B.
                Angaben zur Person, Post- und Kontaktadressen, die zur Bewerbung
                geh&ouml;renden Unterlagen und die darin enthaltenen
                Informationen, wie z.B. Anschreiben, Lebenslauf, Zeugnisse sowie
                weitere im Hinblick auf eine konkrete Stelle oder freiwillig von
                Bewerbern mitgeteilte Informationen zu deren Person oder
                Qualifikation).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Bewerber.
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Bewerbungsverfahren
                (Begr&uuml;ndung und etwaige sp&auml;tere Durchf&uuml;hrung
                sowie m&ouml;gliche sp&auml;tere Beendigung des
                Besch&auml;ftigungsverh&auml;ltnisses.).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Art. 9 Abs. 1 S. 1 lit. b
                DSGVO (Bewerbungsverfahren als vorvertragliches bzw.
                vertragliches Verh&auml;ltnis) (Soweit im Rahmen des
                Bewerbungsverfahrens besondere Kategorien von personenbezogenen
                Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B. Gesundheitsdaten,
                wie Schwerbehinderteneigenschaft oder ethnische Herkunft) bei
                Bewerbern angefragt werden, damit der Verantwortliche oder die
                betroffene Person die ihm bzw. ihr aus dem Arbeitsrecht und dem
                Recht der sozialen Sicherheit und des Sozialschutzes
                erwachsenden Rechte aus&uuml;ben und seinen bzw. ihren
                diesbez&uuml;glichen Pflichten nachkommen kann, erfolgt deren
                Verarbeitung nach Art. 9 Abs. 2 lit. b. DSGVO, im Fall des
                Schutzes lebenswichtiger Interessen der Bewerber oder anderer
                Personen gem. Art. 9 Abs. 2 lit. c. DSGVO oder f&uuml;r Zwecke
                der Gesundheitsvorsorge oder der Arbeitsmedizin, f&uuml;r die
                Beurteilung der Arbeitsf&auml;higkeit des Besch&auml;ftigten,
                f&uuml;r die medizinische Diagnostik, die Versorgung oder
                Behandlung im Gesundheits- oder Sozialbereich oder f&uuml;r die
                Verwaltung von Systemen und Diensten im Gesundheits- oder
                Sozialbereich gem. Art. 9 Abs. 2 lit. h. DSGVO. Im Fall einer
                auf freiwilliger Einwilligung beruhenden Mitteilung von
                besonderen Kategorien von Daten, erfolgt deren Verarbeitung auf
                Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.).
              </li>
            </ul>
            <p>
              <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
            </p>
            <ul className="datenUl">
              <li>
                <strong>eRecruiter Bewerbermanagement-Software:</strong>{" "}
                Recruiting-Plattform und -Leistungen; Dienstanbieter: eRecruiter
                GmbH, Am Winterhafen 4, 4020 Linz, &Ouml;sterreich; Website:{" "}
                <a href="https://www.erecruiter.net">
                  https://www.erecruiter.net
                </a>
                ; Datenschutzerkl&auml;rung:{" "}
                <a href="https://www.erecruiter.net/p/datenschutz">
                  https://www.erecruiter.net/p/datenschutz
                </a>
                .
              </li>
            </ul>
            <h2>Onlinemarketing</h2>
            <p>
              Wir verarbeiten personenbezogene Daten zu Zwecken des
              Onlinemarketings, worunter insbesondere die Vermarktung von
              Werbefl&auml;chen oder Darstellung von werbenden und sonstigen
              Inhalten (zusammenfassend als "Inhalte" bezeichnet) anhand
              potentieller Interessen der Nutzer sowie die Messung ihrer
              Effektivit&auml;t fallen kann.
            </p>
            <p>
              Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in
              einer Datei (sogenannte "Cookie") gespeichert oder &auml;hnliche
              Verfahren genutzt, mittels derer die f&uuml;r die Darstellung der
              vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert
              werden. Zu diesen Angaben k&ouml;nnen z.B. betrachtete Inhalte,
              besuchte Webseiten, genutzte Onlinenetzwerke, aber auch
              Kommunikationspartner und technische Angaben, wie der verwendete
              Browser, das verwendete Computersystem sowie Angaben zu
              Nutzungszeiten geh&ouml;ren. Sofern Nutzer in die Erhebung ihrer
              Standortdaten eingewilligt haben, k&ouml;nnen auch diese
              verarbeitet werden.
            </p>
            <p>
              Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
              nutzen wir zur Verf&uuml;gung stehende IP-Masking-Verfahren (d.h.,
              Pseudonymisierung durch K&uuml;rzung der IP-Adresse) zum Schutz
              der Nutzer. Generell werden im Rahmen des Onlinemarketingverfahren
              keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
              gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter
              der Onlinemarketingverfahren kennen nicht die tats&auml;chlich
              Identit&auml;t der Nutzer, sondern nur die in deren Profilen
              gespeicherten Angaben.
            </p>
            <p>
              Die Angaben in den Profilen werden im Regelfall in den Cookies
              oder mittels &auml;hnlicher Verfahren gespeichert. Diese Cookies
              k&ouml;nnen sp&auml;ter generell auch auf anderen Webseiten die
              dasselbe Onlinemarketingverfahren einsetzen, ausgelesen und zu
              Zwecken der Darstellung von Inhalten analysiert als auch mit
              weiteren Daten erg&auml;nzt und auf dem Server des
              Onlinemarketingverfahrensanbieters gespeichert werden.
            </p>
            <p>
              Ausnahmsweise k&ouml;nnen Klardaten den Profilen zugeordnet
              werden. Das ist der Fall, wenn die Nutzer z.B. Mitglieder eines
              sozialen Netzwerks sind, dessen Onlinemarketingverfahren wir
              einsetzen und das Netzwerk die Profile der Nutzer mit den
              vorgenannten Angaben verbindet. Wir bitten darum, zu beachten,
              dass Nutzer mit den Anbietern zus&auml;tzliche Abreden, z.B. durch
              Einwilligung im Rahmen der Registrierung, treffen k&ouml;nnen.
            </p>
            <p>
              Wir erhalten grunds&auml;tzlich nur Zugang zu zusammengefassten
              Informationen &uuml;ber den Erfolg unserer Werbeanzeigen. Jedoch
              k&ouml;nnen wir im Rahmen sogenannter Konversionsmessungen
              pr&uuml;fen, welche unserer Onlinemarketingverfahren zu einer
              sogenannten Konversion gef&uuml;hrt haben, d.h. z.B., zu einem
              Vertragsschluss mit uns. Die Konversionsmessung wird alleine zur
              Analyse des Erfolgs unserer Marketingma&szlig;nahmen verwendet.
            </p>
            <p>
              Solange nicht anders angegeben, bitten wir Sie davon auszugehen,
              dass verwendete Cookies f&uuml;r einen Zeitraum von zwei Jahren
              gespeichert werden.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
              Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
              bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
              Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage
              unserer berechtigten Interessen (d.h. Interesse an effizienten,
              wirtschaftlichen und empf&auml;ngerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang m&ouml;chten wir Sie auch auf
              die Informationen zur Verwendung von Cookies in dieser
              Datenschutzerkl&auml;rung hinweisen.
            </p>
            <ul className="datenUl">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten&nbsp;
                (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Ger&auml;te-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten), Interessenten.
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Tracking (z.B.
                interessens-/verhaltensbezogenes Profiling, Nutzung von
                Cookies), Remarketing, Besuchsaktionsauswertung,
                Interessenbasiertes und verhaltensbezogenes Marketing, Profiling
                (Erstellen von Nutzerprofilen), Konversionsmessung (Messung der
                Effektivit&auml;t von Marketingma&szlig;nahmen),
                Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
                wiederkehrender Besucher).
              </li>
              <li>
                <strong>Sicherheitsma&szlig;nahmen:</strong> IP-Masking
                (Pseudonymisierung der IP-Adresse).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO).
              </li>
              <li>
                <strong>Widerspruchsm&ouml;glichkeit (Opt-Out):</strong> Wir
                verweisen auf die Datenschutzhinweise der jeweiligen Anbieter
                und die zu den Anbietern angegebenen
                Widerspruchsm&ouml;glichkeiten (sog. "Opt-Out"). Sofern keine
                explizite Opt-Out-M&ouml;glichkeit angegeben wurde, besteht zum
                einen die M&ouml;glichkeit, dass Sie Cookies in den
                Einstellungen Ihres Browsers abschalten. Hierdurch k&ouml;nnen
                jedoch Funktionen unseres Onlineangebotes eingeschr&auml;nkt
                werden. Wir empfehlen daher zus&auml;tzlich die folgenden
                Opt-Out-M&ouml;glichkeiten, die zusammenfassend auf jeweilige
                Gebiete gerichtet angeboten werden:a) Europa:{" "}
                <a href="https://www.youronlinechoices.eu">
                  https://www.youronlinechoices.eu
                </a>
                .&nbsp; b) Kanada:{" "}
                <a href="https://www.youradchoices.ca/choices">
                  https://www.youradchoices.ca/choices
                </a>
                . c) USA:{" "}
                <a href="https://www.aboutads.info/choices">
                  https://www.aboutads.info/choices
                </a>
                . d) Gebiets&uuml;bergreifend:{" "}
                <a href="https://optout.aboutads.info">
                  https://optout.aboutads.info
                </a>
                .
              </li>
            </ul>
            <p>
              <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
            </p>
            <ul className="datenUl">
              <li>
                <strong>Google Analytics:</strong> Onlinemarketing und
                Webanalyse; Dienstanbieter: Google Ireland Limited, Gordon
                House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
                Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
                USA; Website:{" "}
                <a href="https://marketingplatform.google.com/intl/de/about/analytics/">
                  https://marketingplatform.google.com/intl/de/about/analytics/
                </a>
                ; Datenschutzerkl&auml;rung:{" "}
                <a href="https://policies.google.com/privacy">
                  https://policies.google.com/privacy
                </a>
                ; Privacy Shield (Gew&auml;hrleistung Datenschutzniveau bei
                Verarbeitung von Daten in den USA):{" "}
                <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                </a>
                ; Widerspruchsm&ouml;glichkeit (Opt-Out): Opt-Out-Plugin:{" "}
                <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                ,&nbsp; Einstellungen f&uuml;r die Darstellung von
                Werbeeinblendungen:{" "}
                <a href="https://adssettings.google.com/authenticated">
                  https://adssettings.google.com/authenticated
                </a>
                .
              </li>
            </ul>
            <h2>Pr&auml;senzen in sozialen Netzwerken (Social Media)</h2>
            <p>
              Wir unterhalten Onlinepr&auml;senzen innerhalb sozialer Netzwerke
              und verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort
              aktiven Nutzern zu kommunizieren oder um Informationen &uuml;ber
              uns anzubieten.
            </p>
            <p>
              Wir weisen darauf hin, dass dabei Daten der Nutzer au&szlig;erhalb
              des Raumes der Europ&auml;ischen Union verarbeitet werden
              k&ouml;nnen. Hierdurch k&ouml;nnen sich f&uuml;r die Nutzer
              Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der
              Nutzer erschwert werden k&ouml;nnte. Im Hinblick auf US-Anbieter,
              die unter dem Privacy-Shield zertifiziert sind oder vergleichbare
              Garantien eines sicheren Datenschutzniveaus bieten, weisen wir
              darauf hin, dass sie sich damit verpflichten, die
              Datenschutzstandards der EU einzuhalten.
            </p>
            <p>
              Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
              Regelfall f&uuml;r Marktforschungs- und Werbezwecke verarbeitet.
              So k&ouml;nnen z.B. anhand des Nutzungsverhaltens und sich daraus
              ergebender Interessen der Nutzer Nutzungsprofile erstellt werden.
              Die Nutzungsprofile k&ouml;nnen wiederum verwendet werden, um z.B.
              Werbeanzeigen innerhalb und au&szlig;erhalb der Netzwerke zu
              schalten, die mutma&szlig;lich den Interessen der Nutzer
              entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den
              Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten
              und die Interessen der Nutzer gespeichert werden. Ferner
              k&ouml;nnen in den Nutzungsprofilen auch Daten unabh&auml;ngig der
              von den Nutzern verwendeten Ger&auml;te gespeichert werden
              (insbesondere, wenn die Nutzer Mitglieder der jeweiligen
              Plattformen sind und bei diesen eingeloggt sind).
            </p>
            <p>
              F&uuml;r eine detaillierte Darstellung der jeweiligen
              Verarbeitungsformen und der Widerspruchsm&ouml;glichkeiten
              (Opt-Out) verweisen wir auf die Datenschutzerkl&auml;rungen und
              Angaben der Betreiber der jeweiligen Netzwerke.
            </p>
            <p>
              Auch im Fall von Auskunftsanfragen und der Geltendmachung von
              Betroffenenrechten weisen wir darauf hin, dass diese am
              effektivsten bei den Anbietern geltend gemacht werden k&ouml;nnen.
              Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer
              und k&ouml;nnen direkt entsprechende Ma&szlig;nahmen ergreifen und
              Ausk&uuml;nfte geben. Sollten Sie dennoch Hilfe ben&ouml;tigen,
              dann k&ouml;nnen Sie sich an uns wenden.
            </p>
            <ul className="datenUl">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
                Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
                Inhaltsdaten&nbsp; (z.B. Texteingaben, Fotografien, Videos),
                Nutzungsdaten&nbsp; (z.B. besuchte Webseiten, Interesse an
                Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Ger&auml;te-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
                Kommunikation, Tracking (z.B. interessens-/verhaltensbezogenes
                Profiling, Nutzung von Cookies), Remarketing.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art.
                6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <p>
              <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
            </p>
            <ul className="datenUl">
              <li>
                <strong>Instagram :</strong> Soziales Netzwerk; Dienstanbieter:
                Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA;
                Website:{" "}
                <a href="https://www.instagram.com">
                  https://www.instagram.com
                </a>
                ; Datenschutzerkl&auml;rung:{" "}
                <a href="https://instagram.com/about/legal/privacy">
                  https://instagram.com/about/legal/privacy
                </a>
                .
              </li>
              <li>
                <strong>LinkedIn:</strong> Soziales Netzwerk; Dienstanbieter:
                LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2,
                Irland; Website:{" "}
                <a href="https://www.linkedin.com">https://www.linkedin.com</a>;
                Datenschutzerkl&auml;rung:{" "}
                <a href="https://www.linkedin.com/legal/privacy-policy">
                  https://www.linkedin.com/legal/privacy-policy
                </a>
                ; Privacy Shield (Gew&auml;hrleistung Datenschutzniveau bei
                Verarbeitung von Daten in den USA):{" "}
                <a href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active">
                  https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active
                </a>
                ; Widerspruchsm&ouml;glichkeit (Opt-Out):{" "}
                <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">
                  https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                </a>
                .
              </li>
              <li>
                <strong>Xing:</strong> Soziales Netzwerk; Dienstanbieter: XING
                AG, Dammtorstra&szlig;e 29-32, 20354 Hamburg, Deutschland;
                Website: <a href="https://www.xing.de">https://www.xing.de</a>;
                Datenschutzerkl&auml;rung:{" "}
                <a href="https://privacy.xing.com/de/datenschutzerklaerung">
                  https://privacy.xing.com/de/datenschutzerklaerung
                </a>
                .
              </li>
            </ul>
            <h2>L&ouml;schung von Daten</h2>
            <p>
              Die von uns verarbeiteten Daten werden nach Ma&szlig;gabe der
              gesetzlichen Vorgaben gel&ouml;scht, sobald deren zur Verarbeitung
              erlaubten Einwilligungen widerrufen werden oder sonstige
              Erlaubnisse entfallen (z.B., wenn der Zweck der Verarbeitung
              dieser Daten entfallen ist oder sie f&uuml;r den Zweck nicht
              erforderlich sind).
            </p>
            <p>
              Sofern die Daten nicht gel&ouml;scht werden, weil sie f&uuml;r
              andere und gesetzlich zul&auml;ssige Zwecke erforderlich sind,
              wird deren Verarbeitung auf diese Zwecke beschr&auml;nkt. D.h.,
              die Daten werden gesperrt und nicht f&uuml;r andere Zwecke
              verarbeitet. Das gilt z.B. f&uuml;r Daten, die aus handels- oder
              steuerrechtlichen Gr&uuml;nden aufbewahrt werden m&uuml;ssen oder
              deren Speicherung zur Geltendmachung, Aus&uuml;bung oder
              Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte
              einer anderen nat&uuml;rlichen oder juristischen Person
              erforderlich ist.
            </p>
            <p>
              Weitere Hinweise zu der L&ouml;schung von personenbezogenen Daten
              k&ouml;nnen ferner im Rahmen der einzelnen Datenschutzhinweise
              dieser Datenschutzerkl&auml;rung erfolgen.
            </p>
            <h2>Rechte der betroffenen Personen</h2>
            <p>
              Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu,
              die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
            </p>
            <ul className="datenUl">
              <li>
                <strong>
                  Widerspruchsrecht: Sie haben das Recht, aus Gr&uuml;nden, die
                  sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
                  die Verarbeitung der Sie betreffenden personenbezogenen Daten,
                  die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                  Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese
                  Bestimmungen gest&uuml;tztes Profiling. Werden die Sie
                  betreffenden personenbezogenen Daten verarbeitet, um
                  Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
                  Widerspruch gegen die Verarbeitung der Sie betreffenden
                  personenbezogenen Daten zum Zwecke derartiger Werbung
                  einzulegen; dies gilt auch f&uuml;r das Profiling, soweit es
                  mit solcher Direktwerbung in Verbindung steht.
                </strong>
              </li>
              <li>
                <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben
                das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
              </li>
              <li>
                <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
                Best&auml;tigung dar&uuml;ber zu verlangen, ob betreffende Daten
                verarbeitet werden und auf Auskunft &uuml;ber diese Daten sowie
                auf weitere Informationen und Kopie der Daten entsprechend den
                gesetzlichen Vorgaben.
              </li>
              <li>
                <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend
                den gesetzlichen Vorgaben das Recht, die Vervollst&auml;ndigung
                der Sie betreffenden Daten oder die Berichtigung der Sie
                betreffenden unrichtigen Daten zu verlangen.
              </li>
              <li>
                <strong>
                  Recht auf L&ouml;schung und Einschr&auml;nkung der
                  Verarbeitung:
                </strong>{" "}
                Sie haben nach Ma&szlig;gabe der gesetzlichen Vorgaben das
                Recht, zu verlangen, dass Sie betreffende Daten
                unverz&uuml;glich gel&ouml;scht werden, bzw. alternativ nach
                Ma&szlig;gabe der gesetzlichen Vorgaben eine Einschr&auml;nkung
                der Verarbeitung der Daten zu verlangen.
              </li>
              <li>
                <strong>Recht auf Daten&uuml;bertragbarkeit:</strong> Sie haben
                das Recht, Sie betreffende Daten, die Sie uns bereitgestellt
                haben, nach Ma&szlig;gabe der gesetzlichen Vorgaben in einem
                strukturierten, g&auml;ngigen und maschinenlesbaren Format zu
                erhalten oder deren &Uuml;bermittlung an einen anderen
                Verantwortlichen zu fordern.
              </li>
              <li>
                <strong>Beschwerde bei Aufsichtsbeh&ouml;rde:</strong> Sie haben
                ferner nach Ma&szlig;gabe der gesetzlichen Vorgaben das
                Recht,&nbsp; bei einer Aufsichtsbeh&ouml;rde, insbesondere in
                dem Mitgliedstaat Ihres gew&ouml;hnlichen Aufenthaltsorts, Ihres
                Arbeitsplatzes oder des Orts des mutma&szlig;lichen
                Versto&szlig;es Beschwerde einzulegen, wenn Sie der Ansicht
                sind, dass die Verarbeitung der Sie betreffenden
                personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t.
              </li>
            </ul>
          </p>

          <div className="footerUnternehmen" id="footerDesktop">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>

            <div className="right">
              <MaxerlRed width="150px" className="redLogoUnternehmen" />
              <MaxerlRed
                width="150px"
                className="redLogoUnternehmenFixed"
                style={{ marginRight: -65 }}
              />
              <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
              <p>
                Mag.a Karin Weiss
                <br /> +43 650 49 789 64
                <br />{" "}
                <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
                <br />
                <br />
                <NavHashLink to="/impressum#root">Impressum</NavHashLink>
                <NavHashLink to="/agbs#root">AGBs</NavHashLink>
                <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
              </p>
            </div>
          </div>
          <div className="footerUnternehmen" id="footerMobile">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="right" id="mobileRight">
        <MaxerlRed width="150px" className="redLogoUnternehmen" />
        <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
        <p>
          Mag.a Karin Weiss
          <br /> +43 650 49 789 64
          <br />{" "}
          <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
          <br />
          <br />
          <NavHashLink to="/impressum#root">Impressum</NavHashLink>
          <NavHashLink to="/agbs#root">AGBs</NavHashLink>
          <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
        </p>
      </div>
      <div className="whiteUnder"></div>
    </div>
  );
}
