import React, { useEffect } from "react";
import "./index.css";
import MaxerlRed from "../MaxerlRed";
import { MDBBtn } from "mdbreact";
import { FaLinkedinIn, FaXing } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import MaxerlWhite from "../MaxerlWhite";
import { NavHashLink } from "react-router-hash-link";

export default function KontaktScreen(props) {

  useEffect(() => {
    const currentPage = props.location.pathname;

    props.trackPage(currentPage);
  }, [props]);

  const [big, setBigger] = React.useState(false);

  useEffect(() => {
    console.log(window.innerHeight);
    if (window.innerHeight > 1120) {
      setBigger(true);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", () => {
//      console.log(window.scrollY);
      let minus = 1050;
      if (document.body.clientHeight > 1340) {
        minus = 1300;
      }
      if (document.body.clientHeight > 1400) {
        minus = 1320;
      }
      if (window.scrollY > document.body.clientHeight - minus) {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "180px";
          redLogo.style.marginRight = "-65px";
        }
      } else {
        if (document.getElementsByClassName("redLogoUnternehmen")[0] !== null) {
          const redLogo = document.getElementsByClassName(
            "redLogoUnternehmen"
          )[0];

          redLogo.style.position = "fixed";
          redLogo.style.bottom = "90px";
        }
      }
    });
  }, []);
  return (
    <div id="all">
      <div className="allContainer">
        <div className="mainContainerUnt">
          <div className="kontMain">
            <div
              className="kontaktContainer"
              style={
                big === true ? { minHeight: "1000px" } : { minHeight: "100px" }
              }
            >
              <div id="mobileContainer">
                <h1>
                  Ich freue mich
                  <br /> &uuml;ber Ihre Kontakt-
                  <br /> aufnahme.
                </h1>
                <p className="karin">Mag.a Karin Weiss</p>
                <p style={{ marginBottom: "15px" }}>
                  Telefon: +43 650 49 789 64
                  <br /> E-Mail:{" "}
                  <a
                    href="mailto:office@karin-weiss.at"
                    className="mailHoverWhite"
                  >
                    office@karin-weiss.at
                  </a>
                  <br />
                </p>
              </div>
            </div>
            <div className="longImgBox" id="mobileBox"></div>
            <div className="longImgBox" id="desktopBox"></div>
          </div>
          <div className="footerUnternehmen" id="footerDesktop">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>

            <div className="right">
              <MaxerlRed width="150px" className="redLogoUnternehmen" />
              <MaxerlRed
                width="150px"
                className="redLogoUnternehmenFixed"
                style={{ marginRight: -65 }}
              />
              <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
              <p>
                Mag.a Karin Weiss
                <br /> +43 650 49 789 64
                <br />{" "}
                <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
                <br />
                <br />
                <NavHashLink to="/impressum#root">Impressum</NavHashLink>
                <NavHashLink to="/agbs#root">AGBs</NavHashLink>
                <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
              </p>
            </div>
          </div>
          <div className="footerUnternehmen" id="footerMobile">
            <div className="icons">
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.xing.com/profile/Karin_Weiss69",
                    "_blank"
                  )
                }
              >
                <FaXing size={25} color="white" />
              </MDBBtn>

              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/karin-weiss-b4b89b21/",
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn size={25} color="white" />
              </MDBBtn>
              <MDBBtn
                className="svgDivs"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/k.a.r.i.n.w.e.i.s.s/",
                    "_blank"
                  )
                }
              >
                <AiFillInstagram size={25} color="white" />
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="right" id="mobileRight">
        <MaxerlRed width="150px" className="redLogoUnternehmen" />
        <MaxerlWhite width="150px" className="whiteLogoUnternehmen" />
        <p>
          Mag.a Karin Weiss
          <br /> +43 650 49 789 64
          <br />{" "}
          <a href="mailto:office@karin-weiss.at">office@karin-weiss.at</a>
          <br />
          <br />
          <NavHashLink to="/impressum#root">Impressum</NavHashLink>
          <NavHashLink to="/agbs#root">AGBs</NavHashLink>
          <NavHashLink to="/datenschutz#root">Datenschutz</NavHashLink>
        </p>
      </div>
      <div className="whiteUnder"></div>
    </div>
  );
}
